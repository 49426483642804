import React, { useEffect,useState } from 'react';
import Nav from '../nav';
import '../about/style.css';
import { Link } from 'react-router-dom';
import teamicon1 from '../../assets/images/team-icon1.webp'
import teamicon2 from '../../assets/images/team-work 1.webp'
import teamicon3 from '../../assets/images/team 1.webp'
import MissionImg from '../../assets/images/Image.webp'
import image1 from '../../assets/images/.webp';
import image2 from '../../assets/images/B.webp';
import image3 from '../../assets/images/C.webp';
import icon1 from '../../assets/images/Icon.svg';
import icon2 from '../../assets/images/Icon2.svg';
import icon3 from '../../assets/images/Icon3.svg';
import ContactForm from '../contact-profile';
import Footer from '../footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LuChevronRightCircle } from 'react-icons/lu'
function About() {

    useEffect(() => {
        AOS.init({ duration: 1500 });
    })

    const [over, setOver] = useState(1)

    function updateOver(id) {
        setOver(id)
    }

    const [leave, setLeave] = useState(1)

    function updateLeave(id) {
        setLeave(id)
    }
    console.log(updateOver)

    return (
        <>

            <Nav />

            <div className="row m-0">
                <div className="col common_bg">

                    <div className="container" id='about'>

                        <div className="row m-0">
                            <div className="col-sm-12 col-md-12 col-lg-6 about_content" data-aos="fade-right">
                                <span className="section_heading"><img src={teamicon1} className='me-2' />About Us</span>
                                <h1 className='mt-3'>We are <span>StaveoTech, Your Salesforce Cloud</span> Alliance Partner</h1>
                                <p className='para'>
                                    Established in 2023 and headquartered in New Delhi, India, we are your trusted partner for all your Salesforce needs. Our primary focus is on providing comprehensive support for Salesforce products, with our dedicated delivery teams operating from India.
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 image">
                                <img src={MissionImg} className='img-fluid' data-aos="fade-left" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row about_banner m-0">
                <div className="col">

                    <div className="container common">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6 padding">
                                <span className="section_heading"><img src={teamicon2} className='me-2' />Our Mission</span>
                                <h2 data-aos="zoom-in" data-aos-duration="2000">We are on a mission to empower companies to <span className='typeface'>reach new heights of success and growth</span>   </h2>
                                <p className='para' data-aos="fade-up" data-aos-duration="2200">
                                    Our mission is to empower companies to achieve maximum business success and growth by delivering Tailored Salesforce Solutions. Our team consists of seasoned, Certified Salesforce Consultants, Developers, and Administrators.
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 about_service">
                                <span className="section_heading"><img src={teamicon3} className='me-2' />Expertise and Excellence</span>
                                <h2 data-aos="zoom-in" data-aos-duration="2000">At StaveoTech, we take pride<br></br> in our team of <span className='typeface'>Salesforce-certified professionals</span></h2>
                                <p className='para' data-aos="fade-up" data-aos-duration="2200">
                                    Their collective experience has been instrumental in the successful implementation of numerous Salesforce Projects. Furthermore, our commitment to excellence extends to the AppExchange, where we're actively building a presence to offer unique out-of-the-box solutions.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            {/* ---------- Your Alliance Partner ------------- */}

            <div className="row common m-0 about_partner_banner">
                <div className="col">
                    <div className="container">


                        <div className="row">
                            <div className="col partner-sect">
                                <h1 data-aos="fade-in">Your Salesforce Cloud Alliance Partner</h1>
                                <p className='para'>
                                    Hello, and welcome to StaveoTech, your dedicated Salesforce Cloud Alliance Partner. Our commitment<br></br> is to be your unwavering support, offering end-to-end assistance across your suite of Salesforce<br></br> products and third-party applications.
                                </p>
                            </div>

                            <div className='row'  style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                <div className="col-sm-12 col-md-12 col-lg-3 partner-sect-card">

                                    <img src={image3} alt="" className={over === 2 ? "zoomover" : "default"} onMouseEnter={() => updateOver(2)}/>

                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-3 partner-sect-card">
                                    
                                    <img src={image1} alt="..." className={over === 1 ? "mid_image" : "default mid_image"}  onMouseMove={() => updateOver(1)}/>
                                    
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-3 partner-sect-card">

                                    <img src={image2} alt="" className={over === 3 ? "zoomover" : "default"}  onMouseEnter={() => updateOver(3)}/>

                                </div>
                         </div>


                            <div className="row partner-sect">
                                <span className="section_heading fs-5">Our Differentiator</span>
                                <h2 data-aos="fadein">Where we seamlessly blend innovation<br></br> and expertise</h2>
                                <p className='pt-3 px-5 para' data-aos="fadein">
                                    When you choose to partner with StaveoTech, you're not just gaining Salesforce consultation; you're embracing a holistic approach to systems architecture. Our top priority is seamlessly integrating our expertise with your team and crafting solutions that empower you to expand and outpace your competition. We take pride in maintaining an open line of communication with our esteemed clients, serving as a steadfast pillar of support as you venture into the realm of Customer Relationship Management. StaveoTech, ensure your business is equipped with cutting-edge technology to revolutionize your operational landscape.
                                </p>
                                <Link to="/our-services">
                                    <button type="submit" className="btn btn-lg Explore-button">Explore Our Services
                                        <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                    </button>
                                </Link>
                            </div>



                        </div>
                    </div>

                </div>
            </div>



            {/* ---------------- Working Benefits  --------------- */}
            <div className="container partner-sect">

                <div className="row common mobile-cards">
                    <h1 className='mb-5' data-aos="fadein">The benefits of working with us</h1>
                    <div className="col-sm-12 col-md-12 col-lg-3 benefits-card" data-aos="zoom-in">
                        <div class="card px-1 py-2 fs-6 border-0">

                            <div class="card-body">
                                <img src={icon1} />
                                <div className='card-title'>Salesforce Certified Professionals</div>
                                <div class="card-text mt-3">At StaveoTech, our team boasts certified Salesforce professionals who specialize in guiding companies through the implementation of Salesforce. Our commitment to ongoing learning is unyielding, as we invest in continuous training and mentoring to enhance our understanding of Salesforce.</div>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-3 benefits-card" data-aos="zoom-in">
                        <div class="card px-1 py-2 fs-6 border-0">

                            <div class="card-body">
                                <img src={icon2} />
                                <div className='card-title'>Harnessing cloud computing expertise</div>
                                <div class="card-text mt-3">Our extensive cloud computing experience positions us perfectly to catalyze businesses on their journey to the cloud, unlocking the competitive advantage of digitization.</div>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 benefits-card" data-aos="zoom-in">
                        <div class="card px-1 py-2 fs-6 border-0">

                            <div class="card-body">
                                <img src={icon3} />
                                <div className='card-title'>Comprehensive Support</div>
                                <div class="card-text mt-3">StaveoTech offers a full-circle, 360-degree support system encompassing Salesforce products and an array of third-party apps. We're here to ensure your business thrives in this interconnected landscape</div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <ContactForm />
            <Footer />
        </>
    )
}

export default About;