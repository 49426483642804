import React from 'react'
import { Link } from 'react-router-dom';
import ExpJson from '../js/industryExp.js'
import { GoArrowUpRight } from "react-icons/go";

function IndustryExp() {

  return (
    <div>
       
               

                    <div className="row m-0">
                        <div className="col industry_services">

                        {ExpJson.map((item) => (
                       <div class="row exp-tabs" key={item.id}>
                        <Link to={`/Industry-expertise/${item.id}`} className="linking"> 
                            <div class="col-10 exp-tabs-href">
                                <img src={item.icon} className="me-4" alt='..'/>{item.heading}
                            </div>
                            <div className="col-2 arrow">
                            <GoArrowUpRight />
                            </div>
                            </Link>
                        </div>
                       
                    ))}
                        </div>
                    </div>

                </div>
           
  )
}

export default IndustryExp
