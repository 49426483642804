import React from "react";
import { NavLink,Link } from "react-router-dom";
import './style.css'
import Nav from "../nav";
import ContactForm from "../contact-profile";
import { IoRemoveOutline } from "react-icons/io5";
import Footer from "../footer";

function Policy() {
    return (
        <>

            <Nav />

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb policy-breadcrumb">
                                <ol class="breadcrumb  m-0"> 
                                    <li class="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li class="breadcrumb-item" aria-current="page">Privacy Policy</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-10 policy">
                            <h1>Privacy Policy</h1>
                            <p className="para policies_para">
                                This Privacy Policy governs the use of the website www.staveo.com, currently accessible via laptops, desktops, mobile phones, tablets, and other devices. By utilizing our website or providing information through forms, you entrust Staveo Tech Pvt Ltd, its affiliates, and subsidiaries with your personal data. This policy aims to elucidate how we handle your information, affirming our commitment to safeguarding your privacy.
                            </p>

                            {/* <h5 className="policy_title">User Data Collection</h5>
                            <p className="policies_para">
                                We do not collect private information when you navigate our website without engaging with our forms, except for data gathered via cookies.
                            </p> */}

                            <h5 className="policy_title">Information Collection Process</h5>

                            <h6 className="policy_subtitle"> Personal Information You Disclose to Us</h6>
                            <p className="policies_para">
                            At Staveo Tech, we collect personal information that you voluntarily provide to us, such as your name, address, contact details, passwords, security data, and payment information. This information is collected when you express interest in our products or services, participate in activities on our website, or contact us.
                            <br></br><br></br>
                            The types of personal information we collect may include but are not limited to:<br></br>
                            <br></br>
                                <ol className="p-0">
                                    <li>1.  Name and Contact Data: First and last name, email address, postal address, phone number, and similar contact details.</li>
                                    <li>2.  Credentials: Passwords, password hints, and other security information used for authentication and account access.</li>
                                    <li>3.  Payment Data: Information necessary to process payments, such as credit card numbers and associated security codes.</li>
                                    
                                </ol>
                                All personal information provided to Staveo Tech must be accurate, complete, and up-to-date. Please notify us of any changes to your personal information.
                            </p>

                            <h6 className="policy_subtitle"> Information Automatically Collected</h6>
                            <p className="policies_para">
                            We automatically collect certain information when you visit or interact with our Services, including device and usage information, IP address, browser characteristics, operating system, language preferences, and other technical details. This information is essential for maintaining the security and functionality of our Services and for internal analytics purposes.
                            <br></br><br></br>
                            Additionally, like many websites, we utilise cookies and similar technologies to enhance your browsing experience. Please refer to our Cookie Policy for more details on how we use cookies and how you can manage your preferences.
                            </p>


                            <h5 className="policy_title">How We Use Your Information</h5>
                            <p className="policies_para">
                            At Staveo Tech, we use the personal information collected through our Services for various business purposes, including:
                                <br></br><br></br>

                                <ul type="sqaure" className="p-0">
                                    <li>1. Sending you marketing and promotional communications.</li>
                                    <li>2. Posting testimonials with your consent.</li>
                                    <li>3. Delivering targeted advertising.</li>
                                    <li>4. Requesting feedback and improving our services.</li>
                                    <li>5. Protecting the security of our Services and enforcing our terms and policies.</li>
                                    <li>6 .Responding to legal requests and preventing harm.</li>
                                    <li>7. Providing support and resolving inquiries.</li>
                                </ul>
                                    <br></br>
                                We process your personal information based on legitimate business interests, contractual obligations, compliance with legal requirements, and your consent when applicable
                                {/* To modify shared information, email <Link className="underline">hello@staveotech.com.</Link> */}
                            </p>


                            <h5 className="policy_title">Will Your Information Be Shared with Anyone?</h5>
                            <p className="policies_para">
                            We only share your information in specific circumstances, including with your consent, to fulfil legal obligations, or to provide you with services. We may share your data with third-party vendors, service providers, and business partners who assist us in operating our Services, processing payments, or delivering advertisements.
                            <br></br> <br></br>
                            Additionally, we may disclose your information in connection with business transfers, legal proceedings, or to protect the safety and rights of our users and the public.
                            <br></br> <br></br>
                            We do not sell your personal information to third parties for their promotional purposes.

                            </p>


                            <h5 className="policy_title">Do We Use Cookies and Other Tracking Technologies?</h5>
                            <p className="policies_para">
                            Yes, we use cookies and similar tracking technologies to collect and store information about your browsing activities. Please refer to our Cookie Policy for detailed information about how we use cookies and how you can manage your preferences.

                            
                            </p>



                            <h5 className="policy_title">How Long Do We Keep Your Information?</h5>
                            <p className="policies_para">
                            We retain your personal information only for as long as necessary to fulfil the purposes outlined in this privacy policy or as required by law. We implement measures to securely delete or anonymize your data when it is no longer needed for its intended purpose.
                            </p>

                            <h5 className="policy_title">How Do We Keep Your Information Safe?</h5>
                            <p className="policies_para">
                            StaveoTech employs organisational and technical security measures to protect your personal information from unauthorised access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure. We encourage you to use our Services within a secure environment and to safeguard your account credentials.
                            </p>


                            <h5 className="policy_title">Do We Collect Information from Minors?</h5>
                            <p className="policies_para">
                            No, we do not knowingly collect personal information from individuals under the age of 18. If you believe that we have inadvertently collected personal information from a minor, please contact us immediately.
                            </p>

                            <h5 className="policy_title">What Are Your Privacy Rights?</h5>
                            <p className="policies_para">
                            You have the right to review, update, or delete your personal information. If you are a resident of the European Economic Area (EEA), you may also have additional privacy rights as outlined in applicable data protection laws.
                            
                            <br></br><br></br>
                            To exercise your privacy rights or to submit inquiries regarding your personal information, please contact us using the information provided in this policy.

                            </p>

                            <h5 className="policy_title">Controls for Do-Not-Track Features</h5>
                            <p className="policies_para">
                            Our Services do not currently respond to Do-Not-Track signals from web browsers or other mechanisms that indicate your preference not to be tracked online. We will update our practices if a standardised approach to online tracking is established in the future.
                            </p>


                            <h5 className="policy_title">Do We Make Updates to this Policy?</h5>
                            <p className="policies_para">
                            Yes, we may update this privacy policy periodically to reflect changes in our practices or legal requirements. We encourage you to review this policy regularly for any updates. We will notify you of any material changes to this policy through prominent notices on our website or direct communications.
                            </p>

                            <h5 className="policy_title">How Can You Contact Us About This Policy?</h5>
                            <p className="policies_para">
                            If you have any questions, concerns, or comments about this privacy policy or our privacy practices, please contact us at hello@staveotech.com or by mail at:
                            <br></br><br></br>
                            
                            StaveoTech <br></br>
                            
                                  E-168, 3rd Floor,Street No-17,  <br></br>
                                  Sadhnagar, Palam Colony<br></br>
                                  New Delhi 110045<br></br> 
                                  India

                            <br></br><br></br>
                            To review, update, or delete your personal information, please submit a request through the appropriate channels as outlined in this policy.
                            Thank you for taking the time to understand our privacy practices. Your privacy and trust are of utmost importance to us at Staveo Tech.

                            
                            
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm />
            <Footer />

        </>
    )
}

export default Policy