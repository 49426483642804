import React from "react";
import Json from '../components/js/data.json';
import Icon from '../assets/images/ic_3.svg'
import Arrow from '../assets/images/Vector(1).svg'
import { Link } from "react-router-dom";


function Card(){
    
    return(
        <>


        <div className="row our_services">
                    
                    {Json.map((item) => (
                    
                        <div className="col-sm-12 col-md-6 col-lg-3 hello overlay2" key={item.id} data-aos="fade-up">
                        <Link to={`/our-services/${item.id}`} style={{textDecoration:"none"}}>
                            <div className="card pt-4" >
                                <img src={item.icon} className="before" alt="..."/> 
                                <h5 className="card-title before">{item.title}</h5>
                                <p className="card-text para_text pt-2 before">{item.description}</p>

                                <span class="overlay">
                                    <img src={item.image} className="bounce" alt="..."/>
                                    <h3 class="mt-4 mx-0">{item.title}</h3>
                                    <img src={item.arrow}  alt="..."/>
                                </span>
                            </div>
                            </Link>
                        </div>
                        
                    ))}
                    
                    <div className="col-sm-12 col-md-6 col-lg-3 hello overlay2" data-aos="fade-up">
                        <Link to={`/our-services/consulting-services`} style={{textDecoration:"none"}}>
                            <div className="card pt-4" >
                                <img src={Icon} className="before" alt="..."/>
                                <h5 className="card-title before">Consulting Services</h5>
                                <p className="card-text para_text pt-2 before">
                                Experience solutions that solve your business challenges and drive organizational growth with our seasoned Salesforce Consultants.
Harness the expertise of Staveo Tech's consultants to maximize your Salesforce investment and achieve innovative results.
                                </p>

                                <span class="overlay">
                                    <img src={Icon} className="bounce" alt="..."/>
                                    <h3 class="mt-4 mx-0">Consulting Services</h3>
                                    <img src={Arrow}  alt="..."/>
                                </span>
                            </div>
                            </Link>
                        </div>
                </div>
            
        </>
    )
}

export default Card;
