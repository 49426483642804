import React, { useState, useEffect } from 'react'
import { NavLink, Link } from "react-router-dom";
import './style.css'
import banner from '../../assets/images/Consulting_services.svg'
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import Json from '../../components/js/services.json';
import { MdKeyboardArrowRight } from "react-icons/md";
import { LuChevronRightCircle } from "react-icons/lu";
import Exp from '../js/industryExp.js';
import Logo from '../../assets/images/Logo.svg';
import LinkedIn from '../../assets/images/OUTLINE_copy.svg';
import Call from '../../assets/images/call-icon.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import IndustryExp from '../index/industryExp.jsx';
import Json1 from '../js/salesforce.json';
import benefitimg from '../../assets/images/consulting_benefit.png'
import { BiMenuAltRight } from 'react-icons/bi'
import ContactForm from "../contact-profile";
import Footer from "../footer";


const Consulting = () => {


    const [isOpen, setIsOpen] = useState(false);

    const [isOpen1, setIsOpen1] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
    })

    const data =[
       
        {
            "id":"1",
            "icon":"../../images/ic_check.svg",
            "title":"Customized Solutions",
            "para":"Benefit from customized Salesforce solutions tailored to your unique business needs and objectives."
        },
        {
            "id":"2",
            "icon":"../../images/ic_check.svg",
            "title":"Expert Guidance",
            "para":"Access the expertise of certified Salesforce consultants who possess deep knowledge and experience with the platform."
        },
        {
            "id":"3",
            "icon":"../../images/ic_check.svg",
            "title":"Maximizing ROI",
            "para":"Optimize your Salesforce investment with strategic guidance and implementation strategies aimed at maximizing return on investment."
        },
        {
            "id":"4",
            "icon":"../../images/ic_check.svg",
            "title":"Process Optimization",
            "para":"Streamline business processes and workflows with Salesforce consulting services, enhancing efficiency and productivity."
        },
        {
            "id":"5",
            "icon":"../../images/ic_check.svg",
            "title":"Scalability",
            "para":"Ensure your Salesforce implementation is scalable to accommodate future growth and evolving business requirements."
        },
        {
            "id":"6",
            "icon":"../../images/ic_check.svg",
            "title":"Seamless Integration",
            "para":"Integrate Salesforce with existing systems and applications seamlessly, ensuring smooth data flow and interoperability."
        },
        {
            "id":"7",
            "icon":"../../images/ic_check.svg",
            "title":"Ongoing Support",
            "para":"Receive comprehensive support and maintenance services post-implementation, ensuring continued success and optimization of your Salesforce environment."
        }
    ]

    return (

        <>

            {/* <!-- header  --> */}
            <header>
                <nav className="navbar navbar-expand-lg fixed-top" id="navlist">
                    <div className="container" data-aos="fade-down">
                        <NavLink className="navbar-brand" to="/"><img src={Logo} id="logo" alt='...'/></NavLink>
                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <BiMenuAltRight fontSize={50} color="white" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <NavLink className="nav hover-line" to="/">Home</NavLink>
                                </li>
                                <div class="dropup-center">
                                    <li className="nav-item dropdown industry">
                                        <NavLink className="nav  hover-line" to="/our-services">
                                            Our Services
                                        </NavLink>


                                        <ul class="dropdown-menu border-0">
                                            {Json.map((item) => (
                                                <li key={item.id}>
                                                    <Link to={`/our-services/${item.id}`} className="drop">
                                                        {item.title}
                                                    </Link>
                                                </li>

                                            ))}
                                            <li>
                                                <Link to="/our-services/consulting-services" className="drop">Consulting Services</Link>
                                            </li>

                                        </ul>

                                    </li>
                                    <li className="nav-item dropdown exp1">
                                        <NavLink className="nav  hover-line" onClick={() => setIsOpen((prev) => !prev)} type="button" to="/our-services">
                                            Our Services {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}
                                        </NavLink>

                                        {isOpen && (
                                            <ul class="dropdown-menu border-0">
                                                {Json.map((item) => (
                                                    <li key={item.id}>
                                                        <Link to={`/our-services/${item.id}`} className="drop">
                                                            {item.title}
                                                        </Link>
                                                    </li>

                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                </div>
                                <li className="nav-item dropdown industry">
                                    <NavLink className="nav hover-line over" to="">Industry Expertise
                                    </NavLink>

                                    <ul class="dropdown-menu border-0">
                                        {Exp.map((industry) => (
                                            <li key={industry.id}>
                                                <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                    {industry.heading}
                                                </Link>
                                            </li>

                                        ))}
                                    </ul>

                                </li>
                                <li className="nav-item dropdown exp1">
                                    <NavLink className="nav hover-line over" to="" onClick={() => setIsOpen1((prev) => !prev)}>Industry Expertise
                                        {!isOpen1 ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}</NavLink>


                                    {isOpen1 && (
                                        <ul class="dropdown-menu border-0">
                                            {Exp.map((industry) => (
                                                <li key={industry.id}>
                                                    <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                        {industry.heading}
                                                    </Link>
                                                </li>

                                            ))}
                                        </ul>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav hover-line" to="/career">Careers</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav hover-line" to="/About">About</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/Contact">
                                        <button type="submit" className="btn contact py-2" id="call-btn">Contact Us</button>

                                    </NavLink>
                                </li>
                                <li className="nav-item header-icons">
                                    <img src={Call} id="call-icon" alt='...'/>
                                    <a href="https://www.linkedin.com/company/staveo-tech-private-limited/" target="_blank">
                                        <img src={LinkedIn} className="linkedicon" alt='...'/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* navbar ends */}

                <div className="container">

                    <div id="carouselExampleAutoplaying" className="carousel slide">


                        <div className="carousel-inner">
                            <div className="carousel-item active">

                                <div className="row m-0 banner-content">

                                    {/* <div className="col-2 empty"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                                        <h1 data-aos="fade-up">
                                            Unlock Innovation with Staveo Tech's Salesforce Consultants

                                        </h1>

                                        <p className="pe-5 me-2">
                                            Experience solutions that solve your business challenges and drive organizational growth with our seasoned Salesforce Consultants.
                                        </p>
                                        <Link to="/contact">
                                            <button type="submit" className="btn btn-lg Explore-button">Contact Us
                                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                            </button>
                                        </Link>

                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-5 banner-img" data-aos="fade-down">
                                        <img src={banner} className="img-fluid" alt='...'/>
                                    </div>
                                </div>

                            </div>




                        </div>

                    </div>
                </div>

                {/* carousel section ends */}


            </header>
            {/* header ends */}

            <section>

                <div className="container text-center">
                    <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                            {/* <span className="section_heading"><img src="" /> Our Services</span> */}
                            <h1 className="common_heading my-3 animate__animated  animate__fadeInUp animate__delay-0.5s">
                                Unlocking Potential Through Tailored Solutions
                            </h1>
                            <p className="fs-5 py-3 para_text" data-aos="fade-right">Leverage our experts' profound understanding of the Salesforce 
                             platform and extensive experience<br></br> to access a range of SFDC consulting services.</p>
                        </div>
                    </div>

                    <div className="row mx-5">
                        <div className="col-sm-12 col-md-12 col-lg-12 consulting_services_tab">
                         {Json.map((item) => (
                            <div className='tag'  data-aos="fade-up" key={item.id}>
                                <Link to={`/our-services/${item.id}`} className='underline'>
                                <div className='box'>{item.title}</div>
                                </Link>
                            </div>
                         ))}
                        </div>
                    </div>
                    
                   
                   
                </div>
            </section>


           {/* ------------------------- industry expertise -------------------- */}

           <section className="industry_exp">
                <div className="container">

                    <div className="row m-0">
                        <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                            {/* <span><img src=""/> Industry Expertise</span> */}
                            <h1 class="common_heading mt-3" data-aos="fade-up">Our skilled team has expertly<br></br> worked with numerous industries</h1>
                            <p class="py-3 text-white" data-aos="fade-up">Our team's diverse industry experience enables us to deliver innovative<br></br> Salesforce
                             consulting services.</p>

                        </div>
                    </div>

                   <IndustryExp/>

                </div>
            </section>

             {/* --------- Salesforce Expertise section ------------------- */}

             <section style={{backgroundColor:"#f8fbfd"}}>
                <div className="container">

                    {/* <span class="fadeinleft section_heading"> <img src="" /> Our Salesforce Expertise </span> */}
                    <h1 class="mt-3 common_heading" data-aos="fade-right">Salesforce Expertise</h1>

                      <div className="row">
                            {Json1.map((item) => (

                                <div className="col-md-3" key={item.id}>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-12 col-lg-2  consulting_salesforce_exp_heading">
                                            <img src={item.icon} alt='...'></img>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-10 ps-2 consulting_salesforce_exp_heading">
                                            <div className="">{item.name}</div>
                                            
                                        </div>
                                    </div>

                                </div>

                            ))}
                        </div>

                </div>
            </section>
            

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 p-0 consulting_benefits">
                            <img src={benefitimg} className="img-fluid" data-aos="fade-right" alt='...'/>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 " data-aos="fade-up">
                            <div className="row">
                                <h1 className="common_heading">Advantages of Salesforce Consulting Services with StaveoTech</h1>
                            </div>

                            <div className="col-11 ScrollStyle1" id="style-4">


                                <div className="row mt-4">
                                    {data.map((item) => (
                                        <div className=" col-12" key={item.id}>
                                            <div className="row">
                                                <div className="col-2 icon_size">
                                                    <img src={item.icon} className="" alt='...'/>
                                                </div>
                                                <div className="col-10 benefits">
                                                    <span className="card-title sub-heading">{item.title}</span>
                                                    <p className="card-text para_style pt-0 ">{item.para}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <ContactForm />
            <Footer />
        </>

    )
}

export default Consulting
