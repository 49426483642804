import React from 'react'
import Json1 from '../js/salesforce.json';

function SalesforceExp() {
  return (
    

                    <div className="salesforce-grid">

                            <div class="row p-0 m-0">

                                {Json1.map((item) => (
                                    <div className="col-sm-12 col-md-6 col-lg-3 column" key={item.id}>
                                        <img src={item.icon} alt='...'/>
                                        <h6 data-aos="zoom-in" data-aos-duration="500"> {item.name}</h6>
                                    </div>
                                ))}
                            </div>
                      
                    </div>


  
  )
}

export default SalesforceExp
