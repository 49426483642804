import React, { useRef, useState } from "react";
import Nav from "../nav";
import Client2 from '../../assets/images/client2.webp'
import Client1 from '../../assets/images/logo.webp';
import Card from "../service-card";
import Img from '../../assets/images/service_img.webp';
import icon from '../../assets/images/ic_check2.svg';
import emailjs from 'emailjs-com'
import { LuChevronRightCircle } from "react-icons/lu";
import Testimonial from '../index/testimonial'
import ContactForm from "../contact-profile";
import Forms from "../forms";
import 'react-phone-input-2/lib/style.css'
import Footer from "../footer";
import 'aos/dist/aos.css';
import { Form } from "react-router-dom";


function Services() {

    const data = [
        {
            "id": "1",
            "icon": "../../images/sales.svg",
            "title": "Sales Cloud",
            "description": "Streamline your sales processes and drive revenue growth."
        },
        {
            "id": "2",
            "icon": "../../images/ic_service_cloud.svg",
            "title": "Service Cloud​",
            "description": "Elevate customer service experiences and build lasting relationships."
        },
        {
            "id": "3",
            "icon": "../../images/ic_Marketing_cloud.svg",
            "title": "Marketing Cloud",
            "description": "Launch targeted campaigns and engage customers effectively."
        },
        {
            "id": "4",
            "icon": "../../images/financial.svg",
            "title": "Financial Services Cloud",
            "description": "Tailored solutions for the financial sector to drive client satisfaction."
        },
        {
            "id": "5",
            "icon": "../../images/Integration.svg",
            "title": "Integration",
            "description": "Seamlessly connect your Salesforce instance with other platforms for enhanced functionality"
        },
        {
            "id": "6",
            "icon": "../../images/ic_CPQ_Cloud.svg",
            "title": "CPQ & Billing",
            "description": "Optimise quoting and billing processes for increased efficiency."
        },
        {
            "id": "7",
            "icon": "../../images/ic_AppExchange.svg",
            "title": "AppExchange",
            "description": "Explore and integrate a variety of third-party apps to extend Salesforce's capabilities."
        },
        {
            "id": "8",
            "icon": "../../images/salesforce.svg",
            "title": "Salesforce Platform",
            "description": "Leverage the power of the Salesforce platform to build custom solutions."
        },
        {
            "id": "9",
            "icon": "../../images/Experience_Cloud2.svg",
            "title": "Experience Cloud",
            "description": "Create personalised experiences for customers, partners, and employees."
        },
        ,
        {
            "id": "10",
            "icon": "../../images/Nonprofit_Success_Pack.svg",
            "title": "Nonprofit Success Pack",
            "description": "Tailored solutions for nonprofit organisations to manage relationships and donations."
        },
        {
            "id": "11",
            "icon": "../../images/Product_Development.svg",
            "title": "Product Development",
            "description": "Collaborate with our team to develop custom solutions aligned with your business objectives."
        },
        {
            "id": "12",
            "icon": "../../images/Tableau.svg",
            "title": "Tableau",
            "description": "Gain actionable insights and make data-driven decisions with powerful analytics tools."
        },
        {
            "id": "13",
            "icon": "../../images/ic_Pardot_Cloud.svg",
            "title": "Salesforce Pardot",
            "description": "Drive engagement and nurture leads with sophisticated marketing automation."
        },
        {
            "id": "14",
            "icon": "../../images/ic_Mulesoft_Cloud.svg",
            "title": "MuleSoft",
            "description": "Unlock data across various systems and applications for unified insights."
        },
        {
            "id": "15",
            "icon": "../../images/chat_bot.svg",
            "title": "Chatbots",
            "description": "Enhance customer interactions and support processes with intelligent chatbots."
        }
    ]

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
      
      const [errors, setErrors] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      
      const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) { 
          newErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email address';
        }
        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Number is required';
        }
        else if (!/^(\+?\d{1,3})?\d{9,}$/.test(formData.phonenumber)) {
            newErrors.phonenumber = 'Number is invalid';
        }
        if (!formData.message.trim()) {
          newErrors.message = 'Message is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
 


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
           
        if (validateForm()) {
            emailjs
            .sendForm('service_ajcuctg', 'template_24asl6g', form.current, 'QxZUFcPteTc-3ppeF')
            .then(
              () => {
                console.log('SUCCESS!');
              },
              (error) => {
                console.log('FAILED...', error.text);
              },
            )
        }
     
             
      }
   


    return (
        <>

            <Nav />


            {/* landing page service banner section starts*/}

            <div className="row m-0">

                <div className="col service_banner">

                    <div className="container">
                        <div className="row landing_page_service">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <h1 className="common_heading" data-aos="fade-right">Unlock <span>Business Growth at Wrap Speed!</span> With StaveoTech Services</h1>
                                <p className="para" data-aos="fade-right">
                                    Our Certified salesforce professional at your Services, ensuring swift and cost-effective business enhancement. Benefit from expert guideance as we implement your new system,
                                    prioritzing functionality to maximize results.
                                </p>
                                <h6 className="my-4 pt-3" data-aos="fade-up" data-aos-duration="2000">Happy Clients:</h6>
                                <img src={Client2} data-aos="fade-up" data-aos-duration="2000" alt="..."/> <img src={Client1} className="ms-5" alt="..." data-aos="fade-up" data-aos-duration="2600" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-5">
                                {/* <div className="services_contact">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <h5 className="ms-0 py-2">Let's Connect</h5>
                                        <div className="">
                                            <input type="name" name="name" class="form-control  mt-2 mb-0" placeholder="Your name*" onChange={handleChange} required autoComplete='off' />
                                            {errors.name && <span className="invalid-style">{errors.name}</span>}
                                        </div>
                                        <div>
                                            <input type="email" name="email" class="form-control mb-0" placeholder="Email*" onChange={handleChange} required autoComplete='off' />
                                            {errors.email && <span className="invalid-style">{errors.email}</span>}
                                        </div>
                                        <div>
                                            <input className="form-control"  name="phonenumber" 
                                                placeholder="Mobile No." onChange={handleChange} />
                                            {errors.phonenumber && <span className="invalid-style">{errors.phonenumber}</span>}
                                            
                                        </div>
                                        <div className="mb-4">
                                            <textarea type="mobile" name="message" class="form-control mb-0 mt-0 message" placeholder="Message" onChange={handleChange} autoComplete='off' />
                                            {errors.message && <span className="invalid-style">{errors.message}</span>}
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                            <button type="submit" className="btn btn-lg Explore-button mt-0 ms-3" onClick={sendEmail}>Submit
                                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                            </button>

                                        </div>
                                    </form>
                                </div> */}
                                <Forms/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* landing page service banner section ends*/}


            {/* offerings section starts */}

            <div className="row m-0 service_banner2">
                <div className="col">
                    <div className="container">
                        <div className="row">
                            <div className="col" data-aos="fade-in">
                                <h1>Our Offerings</h1>
                                <p className="serv_des my-4 pb-4">
                                    Welcome to a partnership that propels your business forward with tailor-made platform solutions. From initial implementations to intricate custom projects, seamless integrations, ongoing support, and immersive training, we're your trusted ally every step of the way.
                                </p>

                                <Card />


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* offerings section ends */}

            <section>
                <div className="container">

                    <div className="row">
                        <h1>Why Choose Staveo for Salesforce<br></br> Managed Services?</h1>
                        <p className="para pb-3">At Staveo Tech, we understand that choosing the right partner for your Salesforce Managed
                            Services is crucial for unlocking<br></br> the full potential of your Salesforce investment.</p>
                    </div>

                    {/* first */}
                    <div className="row">
                        {data.map((item) => (
                            <div className="col-sm-12 col-md-12 col-lg-4 services_benefits">
                                <div className="row" key={item.id} >
                                    <div className="col-sm-12 col-md-12 col-lg-1 benefit_sect_icon">
                                        <img src={item.icon}></img>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-11">
                                        <h5 className="title">{item.title}</h5>
                                        <p className="desc pt-0">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </section>
            {/* our company benefits section starts */}
            <div className="container company_benefit">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <img src={Img} className="img-fluid" data-aos="fade-up" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 key_benefit" data-aos="fade-down">
                        <h1>Power Your Business with Salesforce Excellence!</h1>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Swift and budget-friendly Salesforce<br></br> deployment</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Customized implementation plan tailored to<br></br> your needs</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Boosted productivity with minimal initial costs</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Personalized implementation plan designed around<br></br> your needs</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Enhanced visibility into key business metrics</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-1">
                                <img src={icon} />
                            </div>
                            <div className="col-11">
                                <p className="pt-0">Rapid return on investment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* our company benefits section ends */}

            <Testimonial />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Services;