import React, { useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import './css/style.css';
import Json from '../components/js/services.json';
import Exp from './js/industryExp.js';
import { LuChevronRightCircle } from "react-icons/lu";
import Logo from '../assets/images/Logo.svg';
import LinkedIn from '../assets/images/OUTLINE_copy.svg';
import BannerImg from '../assets/images/img_banner.svg';
import { BiMenuAltRight } from 'react-icons/bi'
import Call from '../assets/images/call-icon.svg';
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import {Button} from 'rsuite';
import mail from '../assets/images/email (4).svg'
import About from "./about/about";


function Header() {

    const [isOpen,setIsOpen] = useState(false);
   
    const [isOpen1,setIsOpen1] = useState(false);
    return (
        <>
            <header>

                {/* <!-- header  --> */}

                <nav className="navbar navbar-expand-lg fixed-top" id="navlist">
                    <div className="container" data-aos="fade-down">
                        <NavLink className="navbar-brand" to="/"><img src={Logo} id="logo" /></NavLink>
                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <BiMenuAltRight fontSize={50} color="white" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item" onClick={() => window.location.reload(false)}>
                                    <NavLink className="nav hover-line" to="/">Home</NavLink>
                                </li>
                                <div class="dropup-center">
                                <li className="nav-item dropdown industry">
                                        <NavLink className="nav  hover-line" to="/our-services">
                                            Our Services
                                        </NavLink>

                        
                                        <ul class="dropdown-menu border-0">
                                            {Json.map((item) => (
                                                <li key={item.id} onClick={() => window.location.reload(false)}>
                                                    <Link to={`/our-services/${item.id}`} className="drop">
                                                        {item.title}
                                                    </Link>
                                                </li>
                                                
                                            ))}
                                              <li onClick={() => window.location.reload(false)}>
                                                <Link to="/our-services/consulting-services" className="drop">Consulting Services</Link>
                                            </li>
                                        </ul>
                                
                                    </li>
                                    <li className="nav-item dropdown exp1" onClick={()=> setIsOpen((prev) => ! prev)} >
                                        <NavLink className="nav  hover-line" type="button" to="/our-services">
                                            Our Services {! isOpen ? (<MdKeyboardArrowDown className="arrow"/>) : ( <MdKeyboardArrowUp className="arrow"/>)}
                                        </NavLink>

                                        { isOpen && (
                                        <ul class="dropdown-menu border-0">
                                            {Json.map((item) => (
                                                <li key={item.id} onClick={() => window.location.reload(false)}>
                                                    <Link to={`/our-services/${item.id}`} className="drop">
                                                        {item.title}
                                                    </Link>
                                                </li>

                                            ))}
                                              <li onClick={() => window.location.reload(false)}>
                                                <Link to="/our-services/consulting-services" className="drop">Consulting Services</Link>
                                            </li>
                                        </ul>
                                        )}
                                    </li>
                                </div>
                                <li className="nav-item dropdown industry" >
                                    <NavLink className="nav hover-line over" to="">Industry Expertise 
                                 </NavLink>
                                  
                                    <ul class="dropdown-menu border-0">
                                        {Exp.map((industry) => (
                                            <li key={industry.id} onClick={() => window.location.reload(false)}>
                                                <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                    {industry.heading}
                                                </Link>
                                            </li>

                                        ))}
                                    </ul>
                         
                                </li>
                                <li className="nav-item dropdown exp1">
                                    <NavLink className="nav hover-line over" to="" onClick={()=> setIsOpen1((prev) => ! prev)}>Industry Expertise 
                                    {! isOpen1 ? (<MdKeyboardArrowDown className="arrow"/>) : ( <MdKeyboardArrowUp className="arrow"/>)}</NavLink>
                                  
                               
                                    {isOpen1 && (
                                    <ul class="dropdown-menu border-0">
                                        {Exp.map((industry) => (
                                            <li key={industry.id} onClick={() => window.location.reload(false)}>
                                                <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                    {industry.heading}
                                                </Link>
                                            </li>

                                        ))}
                                    </ul>
                                    )}
                                </li>
                                <li className="nav-item" onClick={() => window.location.reload(false)}>
                                    <NavLink className="nav hover-line" to="/career">Careers</NavLink>
                                </li>
                                <li className="nav-item" onClick={() => window.location.reload(false)}>
                                    <NavLink className="nav hover-line" to="/About">About Us</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/Contact">
                                        <button type="submit" className="btn contact py-2" id="call-btn">Contact Us</button>
                                       
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="">
                                        <img src={mail} className="mailicon" />
                                    </a>
                                </li> */}
                                <li className="nav-item header-icons">
                                <img src={Call} id="call-icon" />
                                    <a href="https://www.linkedin.com/company/staveo-tech-private-limited/" target="_blank">
                                        <img src={LinkedIn} className="linkedicon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* navbar ends */}

                <div className="container">

                    <div id="carouselExampleAutoplaying" className="carousel slide">


                        <div className="carousel-inner">
                            <div className="carousel-item active">

                                <div className="row m-0 banner-content">

                                    {/* <div className="col-2 empty"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-6 p-0 ">
                                        <h1 data-aos="fade-up">Elevate Your Business with Salesforce Mastery and Staveo Expertise</h1>
                                        <p>Unlock the Power of Innovative Salesforce Solutions for Lifelong Growth<br></br> We Make Your Systems Better, Smoother, and Ready for Success</p>
                                        <Link to="/our-services">
                                            <button type="submit" className="btn btn-lg Explore-button" appearance="red" id="btn" >Explore Our Services
                                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                            </button>
                                        </Link>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-6 banner-img" data-aos="fade-down">
                                        <img src={BannerImg} className="img-fluid" />
                                    </div>
                                </div>

                            </div>




                        </div>

                    </div>
                </div>

                {/* carousel section ends */}




            </header>

            {/* <!-- header --> */}

        </>
    )
}

export default Header;