export default [
    {
        "id":"Financial-Services",
        "heading":"Financial Services",
        "icon":"../../images/histogram.svg",
        "bannerimg":"../../images/expertise1_banner.webp",
        "cta":"Financial Services",
        "hex":[
            223, 175, 84
        ],
        "banner_heading":"Salesforce: Transforming Financial Services Dynamics",
        "para":"Salesforce enables Financial Services companies to navigate constant market changes while maintaining relevance. With our solutions, deliver personalized and customer-centric experiences that set you apart in a competitive landscape. Innovate lead prospecting and origination to unearth optimal opportunities for maximum profit.",
        "why_us":"Why Choose StaveoTech as Your Salesforce Partner?",
        "why_us_para":"",
        Reasons:[
            {
                "icon":"../../images/expertise_ic1.svg",
                "heading":"Maximize Platform Investments",
                "desc":"Work with experienced Salesforce Consulting partners to maximize your investments across the platform. Our expertise ensures that you harness the full potential of Salesforce for unparalleled business growth."
            },
            {
                "icon":"../../images/expertise_ic2.svg",
                "heading":"Robust, User-Friendly Solutions",
                "desc":"Create solutions tailored to your specific needs – robust, user-friendly, and adaptable. Our consultants craft solutions that enhance customer experiences, streamline processes, and adapt seamlessly to the evolving Financial Services landscape."
            },
            {
                "icon":"../../images/expertise_ic3.svg",
                "heading":"Customer-Centric Focus",
                "desc":"Our approach is centered on your customers. We help you understand and meet their needs, revolutionizing their experience and processes. Elevate customer satisfaction and loyalty through Salesforce-powered transformations."
            },
            {
                "icon":"../../images/expertise_ic4.svg",
                "heading":"Innovative Financial Technology",
                "desc":"Stay ahead with cutting-edge Financial Services technology and trends. StaveoTech connects you with innovation, ensuring your business is equipped to embrace the future of financial services."
            },
        ],
        "service_benefits_heading":"Salesforce Benefits for Financial Services",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefitimg1.webp",
                "heading":"Complete Customer Visibility",
                "desc":"Gain comprehensive visibility into your customer's financial profiles. Centralize data, eliminate duplicates, and enhance efficiency across your operations."
            },
            {
                "img":"../../images/benefitimg2.webp",
                "heading":"Real-Time Opportunity Analysis",
                "desc":"Uncover new and critical information with real-time data analytics. Salesforce accelerates decision-making by providing timely insights into opportunities."
            },
            {
                "img":"../../images/benefitimg3.webp",
                "heading":"Lifecycle Management Improvement",
                "desc":"Enhance the management of the complete customer lifecycle. From prospecting to retention, optimize processes for sustained growth."
            },
            {
                "img":"../../images/benefitimg4.webp",
                "heading":"Risk Identification and Forecasting",
                "desc":"Identify risks with precision and forecast assets better than before. Salesforce equips you with the tools to navigate financial landscapes with confidence."
            }
        ],
        "comprehensive_service_heading":"Comprehensive Services for Financial Excellence",
        "comprehensive_service_desc":"Transform your financial services operations with Staveo’s Salesforce solutions",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"Lightning Migration"
            },
            {
                "title":"Salesforce Managed Services"
            },
            {
                "title":"Training & Support"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"3rd Party Integrations"
            },
            {
                "title":"Salesforce Enhancements"
            },
            {
                "title":"Salesforce Health Check"
            }
        ],
        "offered_solution_heading":"Specialized Solutions for Financial Services Sectors",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution_icon1.webp",
                "title":"Banking",
                "desc":"Streamline the banking experience, delivering personalized services and revolutionizing customer management."
            },
            {
                "icon":"../../images/solution_icon2.webp",
                "title":"Wealth & Asset Management",
                "desc":"Gain comprehensive visibility into your customer's financial profiles. Centralize data, eliminate duplicates, and enhance efficiency across your operations."
            },
            {
                "icon":"../../images/solution_icon3.webp",
                "title":"Mortgage & Lending",
                "desc":"Simplify mortgage and lending processes for an exceptional loan experience. Unify your business in one platform for transparency and certainty."
            },
            {
                "icon":"../../images/solution_icon4.webp",
                "title":"Insurance",
                "desc":"Provide exceptional customer-centric service, streamline underwriting processes, and boost customer retention in a competitive insurance market."
            }
        ]
    },
    {
        "id":"Healthcare-&-Life-Sciences",
        "heading":"Healthcare & Life Sciences",
        "cta":"Healthcare & Life Sciences",
        "icon":"../../images/health.svg",
        "hex":[
            33, 202, 172
        ],
        "bannerimg":"../../images/expertise2_banner.webp",
        "banner_heading":"Elevate Healthcare & Life Sciences with Salesforce Excellence",
        "para":"In the dynamic landscape of Healthcare and Life Sciences, innovation is not just a goal; it's a necessity. At StaveoTech, we bring you Salesforce solutions tailored for the intricacies of the industry. Transform the way you operate, collaborate, and deliver personalized digital experiences from anywhere. As the healthcare landscape evolves, our scalable Salesforce solutions empower your business to grow and adapt, ensuring you stay at the forefront of innovation.",
        "why_us":"Salesforce Solutions for Healthcare Growth",
        "why_us_para":"",
        Reasons:[
            {
                "icon":"../../images/expertise2_ic1.svg",
                "heading":"Innovative Tools for Immediate Action",
                "desc":"The healthcare industry demands constant innovation and adaptability. Salesforce equips healthcare providers with tools to innovate, adapt, and take immediate action. Gain valuable data insights and foster global cooperation within the healthcare environment."
            },
            {
                "icon":"../../images/expertise2_ic2.svg",
                "heading":"Custom Solutions for Healthcare Challenges",
                "desc":"StaveoTech's deep knowledge of the Salesforce platform and our team's experience in the healthcare industry enable us to build custom solutions addressing specific healthcare challenges. From patient acquisition to technical support, our tailored solutions cater to diverse needs."
            },
            {
                "icon":"../../images/expertise2_ic3.svg",
                "heading":"Tailored Patient Journeys",
                "desc":"Personalization is at the core of patient-centric care. We help you create tailored patient journeys, connecting with patients on a deeper level and enhancing their overall healthcare experience."
            },
            {
                "icon":"../../images/expertise2_ic4.svg",
                "heading":"Efficient Collaboration and Data Insights",
                "desc":"Salesforce streamlines business processes, providing tools for a greater level of patient-centric care. Speed up the conversion and registration process, offer personalized digital experiences, and achieve efficiency at a lower cost with a 360-degree view of patients from anywhere, anytime."
            },
            {
                "icon":"../../images/expertise2_ic5.svg",
                "heading":"Adaptability in a Changing Industry",
                "desc":"Stay ahead in the rapidly changing healthcare industry. Salesforce supports your efforts in delivering better care to patients and positively impacting society. Leverage our expertise to integrate Salesforce with meaningful systems, improving visibility, data tracking, and transforming inefficient workflows."
            },
        ],
        "service_benefits_heading":"Salesforce Benefits for Healthcare & Life Sciences",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefit2img1.webp",
                "heading":"Patient-Centric Care",
                "desc":"Streamline business processes to deliver a greater level of patient-centric care with Salesforce Healthcare solutions."
            },
            {
                "img":"../../images/benefit2img2.webp",
                "heading":"360-Degree View of Patients",
                "desc":"Achieve efficiency at a lower cost with a 360-degree view of patients and customers from anywhere, at any time."
            },
            {
                "img":"../../images/benefit2img3.webp",
                "heading":"Efficient Conversion and Registration",
                "desc":"Speed up the conversion and registration process, providing support and a personalized digital experience to patients and customers."
            },
            {
                "img":"../../images/benefit2img4.webp",
                "heading":"Adaptable in a Changing Industry",
                "desc":"Keep up with the rapidly changing industry and receive support in delivering better care to patients while positively impacting society."
            },
            {
                "img":"../../images/benefit2img5.webp",
                "heading":"Integration with Meaningful Systems",
                "desc":"Integrate Salesforce with systems like CollaborateMD software, KIPU Health EMR, Sunwave EMR, and more to improve visibility, data tracking, and achieve business goals."
            }
        ],
        "comprehensive_service_heading":"Comprehensive Services for Financial Excellence",
        "comprehensive_service_desc":"Transform your financial services operations with Staveo’s Salesforce solutions",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"Lightning Migration"
            },
            {
                "title":"Salesforce Managed Services"
            },
            {
                "title":"Training & Support"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"3rd Party Integrations"
            },
            {
                "title":"Salesforce Enhancements"
            },
            {
                "title":"Salesforce Health Check"
            }
        ],
        "offered_solution_heading":"Healthcare Sectors We Serve",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution2_icon1.svg",
                "title":"Payers",
                "desc":"Build personalized experiences for greater member engagement, collaborate for better treatment, identify high-risk patients, and manage provider networks through Salesforce CRM solutions."
            },
            {
                "icon":"../../images/solution2_icon2.svg",
                "title":"Providers",
                "desc":"Strengthen the relationship between provider and patient, better understand healthcare consumers' needs, and improve agent productivity to provide proper care faster."
            },
            {
                "icon":"../../images/solution2_icon3.svg",
                "title":"Pharma",
                "desc":"Integrate systems and teams on one platform, improving productivity and maximizing ROI. Build personalized communication tools, track patients' performance, and deliver the best patient support programs."
            },
            {
                "icon":"../../images/solution2_icon4.svg",
                "title":"MedTech",
                "desc":"Accelerate business results with complete virtual selling experiences, custom commercialization solutions, driving engagement, and gaining actionable insights."
            }
        ]
    },
    {
        "id":"Manufacturing",
        "heading":"Manufacturing",
        "cta":"Manufacturing Sector",
        "hex":[222, 103, 196],
        "icon":"../../images/qrcode.svg",
        "bannerimg":"../../images/expertise3_banner.webp",
        "banner_heading":"Empowering Manufacturing Excellence with Salesforce Solutions",
        "para":"In the intricate landscape of manufacturing, where operational efficiency is paramount, Salesforce emerges as a game-changer. At StaveoTech, we bring you Salesforce solutions designed to unify your manufacturing experience, reduce operational costs, and infuse innovation into your production processes. Transform the way you meet customer demands and navigate the complexities of the manufacturing industry.",
        "why_us":"Key Advantages of Salesforce Implementation in Manufacturing",
        "why_us_para":"The manufacturing industry, with its myriad points of contact and intricate manual processes, often faces challenges in tracking materials and fostering effective communication between departments, especially in production and delivery.",
        Reasons:[
            {
                "icon":"../../images/expertise3_ic1.svg",
                "heading":"Automate and Simplify Manufacturing Processes",
                "desc":"Leverage the power of Salesforce to automate and simplify your manufacturing processes, reducing manual efforts and enhancing efficiency."
            },
            {
                "icon":"../../images/expertise3_ic2.svg",
                "heading":"Effective Collaboration Between Departments",
                "desc":"Create effective collaboration between departments, breaking down silos and ensuring seamless communication, particularly between production and delivery teams."
            },
            {
                "icon":"../../images/expertise3_ic3.svg",
                "heading":"Transformation of Customer Experience",
                "desc":"Transform the way your customers experience services with Salesforce. From order processing to delivery, enhance every touchpoint for an elevated customer journey."
            },
            {
                "icon":"../../images/expertise3_ic4.svg",
                "heading":"Increased Team Productivity",
                "desc":"Boost team productivity by providing tools and platforms that streamline tasks and empower your workforce."
            },
        ],
        "service_benefits_heading":"Salesforce Benefits for the Manufacturing Industry",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefit3img1.webp",
                "heading":"Comprehensive View of Operations",
                "desc":"Connect different departments to gain a comprehensive view of the entire operation, fostering better decision-making."
            },
            {
                "img":"../../images/benefit3img2.webp",
                "heading":"Efficient Demand Tracking",
                "desc":"Track customers' demands with Salesforce's 360-degree view, enabling better inventory management and preventing under or over-production."
            },
            {
                "img":"../../images/benefit3img3.webp",
                "heading":"Increased Conversion Rates",
                "desc":"Increase conversion rates by tracking leads through the sales process, gaining insights into sales strategies and prospect interactions."
            },
            {
                "img":"../../images/benefit3img4.webp",
                "heading":"Valuable Product Insights",
                "desc":"Obtain valuable insights into product performance, identifying trends, making predictions, and addressing issues promptly to enhance product quality."
            }
        ],
        "comprehensive_service_heading":"StaveoTech Services for the Manufacturing Industry",
        "comprehensive_service_desc":"Elevate your manufacturing operations with StaveoTech's Salesforce excellence. Contact us today to embark on a journey of efficiency, innovation, and sustained growth in the manufacturing industry. Embrace the future of manufacturing with Salesforce-powered solutions!",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"3rd Party Product Integrations"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"Development"
            },
            {
                "title":"Salesforce Managed Services"
            },
            {
                "title":"Salesforce Enhancement"
            }
        ],
        "offered_solution_heading":"Manufacturing Sectors We Specialize In",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution3_icon1.svg",
                "title":"Discrete Manufacturing",
                "desc":"Companies in this sector, responsible for creating or assembling items like vehicles, computers, smartphones, and clothing, benefit from tailored solutions to meet high-demanding industry needs."
            },
            {
                "icon":"../../images/solution3_icon2.svg",
                "title":"Process Manufacturing",
                "desc":"Companies producing pharmaceuticals, chemicals, plastics, and food find automation support for back-office procedures with manufacturing software tailored to their specific needs."
            }
        ]
    },
    {
        "id":"Media-&-Entertainment",
        "heading":"Media & Entertainment",
        "cta":"Media & Entertainment",
        "hex":[236, 77, 81],
        "icon":"../../images/clapperboard.svg",
        "bannerimg":"../../images/expertise4_banner.webp",
        "banner_heading":"Transforming Media and Entertainment with Salesforce Excellence",
        "para":"In the dynamic world of Media and Entertainment, where consumer expectations are ever-changing and technological innovations are reshaping the landscape, Salesforce emerges as the catalyst for personalizing the entire media buying journey. At StaveoTech, we bring you Salesforce solutions designed to meet the evolving needs of the Media and Entertainment industry, helping you stay competitive and align with new market trends.",
        "why_us":"Key Advantages of Salesforce for Media & Entertainment",
        "why_us_para":"The manufacturing industry, with its myriad points of contact and intricate manual processes, often faces challenges in tracking materials and fostering effective communication between departments, especially in production and delivery.",
        Reasons:[
            {
                "icon":"../../images/expertise4_ic1.svg",
                "heading":"Improved Communication and Efficiency",
                "desc":"Enhance communication between departments, media, agencies, and clients. Salesforce centralizes information, reducing the time spent navigating between different platforms and streamlining workflows."
            },
            {
                "icon":"../../images/expertise4_ic2.svg",
                "heading":"Results on a Limited Budget",
                "desc":"Deliver impactful results even with a limited budget. Salesforce solutions are designed to optimize resources and maximize the efficiency of your media and entertainment operations."
            }
        ],
        "service_benefits_heading":"Salesforce Benefits for Media & Entertainment",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefit4img1.webp",
                "heading":"360-Degree Customer View",
                "desc":"Understand customers' preferences with a 360-degree view, allowing you to tailor content and offerings to meet their unique needs."
            },
            {
                "img":"../../images/benefit4img2.webp",
                "heading":"Maximized Ad Revenue and Streamlined Sales",
                "desc":"Maximize ad revenue by streamlining and providing better management of Ad Sales. Salesforce offers a unified system to improve communication between departments, enhancing team effectiveness and aligning with sales goals."
            },
            {
                "img":"../../images/benefit4img3.webp",
                "heading":"Reduced Mistakes and Improved Inventory Visibility",
                "desc":"Minimize mistakes in price configurations and enhance inventory visibility, ensuring smoother operations and improved resource management."
            },
            {
                "img":"../../images/benefit4img4.webp",
                "heading":"Revenue Schedule Management",
                "desc":"Manage revenue schedules better to track ROI and optimize the sales process, providing insights for strategic decision-making."
            }
        ],
        "comprehensive_service_heading":"What We Offer to Media & Entertainment Companies",
        "comprehensive_service_desc":"Elevate your Media and Entertainment operations with StaveoTech's Salesforce excellence. Contact us today to embark on a journey of innovation, efficiency, and sustained growth in the Media and Entertainment industry. Embrace the future of media with Salesforce-powered solutions!",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"3rd Party Product Integrations"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"Lightning Migration"
            },
            {
                "title":"Salesforce Managed Services"
            },
            {
                "title":"Salesforce Enhancement"
            },
            {
                "title":"Training & Support"
            },
            {
                "title":"Salesforce Health Check"
            }
        ],
        "offered_solution_heading":"Sub-Industries Expertise",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution4_icon1.svg",
                "title":"Broadcasting",
                "desc":"Design custom applications to collect and organize relevant data for a powerful customer experience, optimizing content delivery."
            },
            {
                "icon":"../../images/solution4_icon2.svg",
                "title":"Agency & Advertising",
                "desc":"Build customized products for personalized media experiences, shorten release cycles, and guide customers to specific products and services."
            },
            {
                "icon":"../../images/solution4_icon3.svg",
                "title":"Entertainment",
                "desc":"Unify Sales, Marketing, and Analytics to create real-time campaigns, automate discovery, spot trends, and anticipate customer needs for a seamless entertainment experience."
            },
            {
                "icon":"../../images/solution4_icon4.svg",
                "title":"Publishing",
                "desc":"Improve team collaboration, optimize budgets, expand operational productivity, gain visibility into production timelines, increase content production project management, and accelerate response times with a single platform."
            }
        ]
    },
    {
        "id":"Retail-&-Consumer-Goods",
        "heading":"Retail & Consumer Goods",
        "cta":"Retail & Consumer Goods",
        "hex":[73, 88, 236],
        "icon":"../../images/cart.svg",
        "bannerimg":"../../images/expertise5_banner.webp",
        "banner_heading":"Revolutionize Retail & Consumer Goods with Salesforce Expertise",
        "para":"In the ever-evolving landscape of Retail and Consumer Goods, the shopping experience is the linchpin of buying decisions. Companies in this sector continually seek ways to optimize processes, create personalized shopping experiences, and foster client loyalty. Salesforce for Retail & Consumer Goods emerges as the transformative solution, empowering businesses to eliminate manual processes, gain a 360° view of operations, and drive sustainable growth and brand loyalty in the competitive market.",
        "why_us":"Key Solutions for Retail & Consumer Goods",
        "why_us_para":"The manufacturing industry, with its myriad points of contact and intricate manual processes, often faces challenges in tracking materials and fostering effective communication between departments, especially in production and delivery.",
        Reasons:[
            {
                "icon":"../../images/expertise5_ic1.svg",
                "heading":"End-to-End Solutions for Next-Gen Commerce",
                "desc":"Salesforce for Retail & Consumer Goods delivers end-to-end solutions that redefine commerce for the next generation. From demand analysis and buying pattern prediction to distribution and reseller management, our expertise transforms businesses for unparalleled outcomes."
            },
            {
                "icon":"../../images/expertise5_ic2.svg",
                "heading":"Optimized Processes for Personalized Experiences",
                "desc":"Deliver impactful results even with a limited budget. Salesforce solutions are designed to optimize resources and maximize the efficiency of your media and entertainment operations."
            },
            {
                "icon":"../../images/expertise5_ic3.svg",
                "heading":"Insights into Client Behavior",
                "desc":"Gain insights into client behavior through demand analysis and buying pattern prediction. Optimize distribution and reseller management with self-service portals and automation."
            },
            {
                "icon":"../../images/expertise5_ic4.svg",
                "heading":"Efficient Inventory Management",
                "desc":"Streamline inventory management for better project tracking and predict clients' needs. Salesforce ensures accuracy and efficiency in quoting and pricing processes."
            },
        ],
        "service_benefits_heading":"Salesforce Benefits for Retail & Consumer Goods",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefit5img1.webp",
                "heading":"360° Business View",
                "desc":"Eliminate manual processes and gain a 360° view of your business, fostering better decision-making."
            },
            {
                "img":"../../images/benefit5img2.webp",
                "heading":"Client Data Protection",
                "desc":"Protect client data with privacy rules to authenticate client accounts, ensuring secure transactions."
            },
            {
                "img":"../../images/benefit5img3.webp",
                "heading":"Opportunity Onboarding and Cross-Selling",
                "desc":"Take advantage of opportunities to onboard new clients or cross-sell valuable products, driving revenue growth."
            },
            {
                "img":"../../images/benefit5img4.webp",
                "heading":"Quoting and Pricing Accuracy",
                "desc":"Streamline quoting and pricing processes for increased accuracy and efficiency."
            }
        ],
        "comprehensive_service_heading":"Services for the Retail & Consumer Goods Industry",
        "comprehensive_service_desc":"Elevate your retail and consumer goods operations with StaveoTech's Salesforce excellence. Contact us today to embark on a journey of innovation, efficiency, and sustained growth in the Retail & Consumer Goods industry. Transform the shopping experience and build lasting brand loyalty with Salesforce-powered solutions!",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"3rd Party Product Integrations"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"Development"
            },
            {
                "title":"Salesforce Managed Services"
            }
        ],
        "offered_solution_heading":"Retail & Consumer Goods Sub-Industries Expertise",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution5_icon1.svg",
                "title":"Food, Drug, Convenience, Grocery & Restaurants",
                "desc":"Connect consumer experiences with AI-powered insights, improve collaboration with partners, and increase brand awareness for businesses in this sector."
            },
            {
                "icon":"../../images/solution5_icon2.svg",
                "title":"Mass Merchant & Dept Stores",
                "desc":"Build customized products for personalized media experiences, shorten release cycles, and guide customers to specific products and services."
            },
            {
                "icon":"../../images/solution5_icon3.svg",
                "title":"Specialty & Luxury",
                "desc":"Identify growth areas, design tailored approaches, meet the needs of today's shoppers, and build lifetime loyalty for establishments specializing in specific merchandise or luxury items."
            }
        ]
    },
    {
        "id":"Technology-and-High-Tech",
        "heading":"Technology and High-Tech",
        "cta":"Technology and High-Tech",
        "hex":[150, 154, 238    ],
        "icon":"../../images/cloud.svg",
        "bannerimg":"../../images/expertise6_banner.webp",
        "banner_heading":"Revolutionize Your Tech Business with Salesforce Excellence",
        "para":"In the fast-paced realm of technology, where innovation is the currency, Salesforce stands as the catalyst for transformation. At StaveoTech, we empower businesses in the Technology and High-Tech sectors to harness the full potential of Salesforce, building personalized customer journeys, automating critical business functions, and making cutting-edge equipment readily available to customers.",
        "why_us":"The Salesforce Advantage for Tech and High-Tech",
        "why_us_para":"",
        Reasons:[
            {
                "icon":"../../images/expertise6_ic1.svg",
                "heading":"Unified Customer Experience",
                "desc":"Streamline every aspect of your customer experience on a single platform. In an industry that thrives on innovation, Salesforce provides the tools to elevate your business processes and deliver unparalleled customer support, maintaining a competitive advantage."
            },
            {
                "icon":"../../images/expertise6_ic2.svg",
                "heading":"Faster Response Times and Data-Driven Decisions",
                "desc":"Salesforce helps your business stay on top of customer feedback about products, enabling faster response times and providing valuable insights for better data-driven decisions. Overcome production challenges swiftly and accelerate the release of new products."
            },
            {
                "icon":"../../images/expertise6_ic3.svg",
                "heading":"Automate Customer Lifecycles",
                "desc":"Automate customers' lifecycles to save time and money. Salesforce allows your business to align teams and technology seamlessly with your customers, creating efficient workflows that enhance overall productivity."
            }
        ],
        "service_benefits_heading":"Salesforce Benefits for Technology Sectors",
        "service_benefits_desc":"",
        service_benefits:[
            {
                "img":"../../images/benefit6img1.webp",
                "heading":"Real-Time Data-Driven Decisions",
                "desc":"Gain insights for smarter business solutions through real-time, data-driven decisions."
            },
            {
                "img":"../../images/benefit6img2.webp",
                "heading":"Multi-Channel Customer Support",
                "desc":"Provide responsive, multi-channel support for highly technical products, empowering sales and marketing teams with data insights for efficient customer targeting."
            },
            {
                "img":"../../images/benefit6img3.webp",
                "heading":"Targeted Customer Engagement",
                "desc":"Target the right customers for renewal and invest resources wisely in opportunities that make sense."
            },
            {
                "img":"../../images/benefit6img4.webp",
                "heading":"Cloud-Based Solutions",
                "desc":"Meet changing trends with innovative, cloud-based solutions tailored to the dynamic technology industry."
            },
            {
                "img":"../../images/benefit6img5.webp",
                "heading":"Flexible Supply Chain Processes",
                "desc":"Make the supply chain process more flexible with automations and data analytics, optimizing operations."
            },
            {
                "img":"../../images/benefit6img6.webp",
                "heading":"Data Security and Transparency",
                "desc":"Maintain data security and transparency with smart and scalable tools that evolve with your business."
            }
        ],
        "comprehensive_service_heading":"Comprehensive Services for Tech Excellence",
        "comprehensive_service_desc":"Transform your technology business with StaveoTech's Salesforce excellence. Contact us today to embark on a journey of innovation, efficiency, and sustained growth in the Technology and High-Tech sectors. Elevate your business to new heights with Salesforce-powered solutions!",
        comprehensive_service:[
            {
                "title":"Salesforce Implementation"
            },
            {
                "title":"3rd Party Product Integrations"
            },
            {
                "title":"Salesforce Consulting"
            },
            {
                "title":"Development"
            },
            {
                "title":"Managed Services"
            }
        ],
        "offered_solution_heading":"Staveo’s Tech Sub-Industry Expertise",
        "offered_solution_desc":"",
        solutions:[
            {
                "icon":"../../images/solution6_icon1.svg",
                "title":"Hardware Manufacturing",
                "desc":"Responsive, multi-channel support for highly technical products, empowering sales and marketing teams with data insights for efficient customer targeting."
            },
            {
                "icon":"../../images/solution6_icon2.svg",
                "title":"Semiconductors & Components",
                "desc":"Tools to achieve a faster translation of products and processes, consolidate customer data, manage opportunity funnels, and minimize time to market for new chip designs."
            },
            {
                "icon":"../../images/solution6_icon3.svg",
                "title":"Software & Services",
                "desc":"Expertise in building connected solutions to synchronize internal systems, improve team collaboration, streamline customer experience, and provide customizable, user-friendly products."
            }
        ]
    }

]


