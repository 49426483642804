import React,{useEffect} from "react";
import industryExp from "../js/industryExp";
import { useParams,Link } from "react-router-dom";
import './style.css'
import Nav from "../nav";
import ContactForm from "../contact-profile";
import Footer from "../footer";
import { LuChevronRightCircle } from "react-icons/lu";
import AOS from 'aos';
import 'aos/dist/aos.css';


function DetailPage() {

    const { pageId } = useParams()
    const thisPage = industryExp.find(page => page.id == pageId)
    
    useEffect(() => {
        AOS.init({ duration: 2000 });
    })
    
   
    return (
        <>

            <Nav />

            {/* banner section starts */}
            <div className="container expertise-banner">
                <div className="row">
                    <div className="col">
                        <img src={thisPage.bannerimg}  data-aos="zoom-in" alt="..." className="img-fluid" />

                        {/* <CTA /> */}
                        <div className="services_tags_cta " style={{background: `rgb(${thisPage.hex})`}} data-aos="fade-down" data-aos-duration="2800">{thisPage.cta}</div>
                        <h1 className="common_heading">{thisPage.banner_heading}</h1>
                        <p className="para">{thisPage.para}</p>
                        <div className="text-center">
                        <Link to="/our-services">
                            <button type="submit" className="btn btn-lg Explore-button">Explore Our Services
                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                            </button>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* banner section ends */}


            {/* why salesforce section starts  */}

            <div className="row bg1 m-0">
                <div className="col">

                    <div className="container">
                        <div className="row why-our-industry">
                            <div className="col">
                                <h1 className="common_heading" data-aos="fade-up">{thisPage.why_us}</h1>
                                <p className="para">{thisPage.why_us_para}</p>
                            </div>
                        </div>

                        <div className="row">
                            {thisPage.Reasons.map((item) => (

                                <div className="col-md-6 why_industry_reasons" key={item.id}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-1">
                                            <img src={item.icon} data-aos="zoom-in" alt="..."></img>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-11 ps-3">
                                            <h5 className="title" >{item.heading}</h5>
                                            <p className="desc pt-1" data-aos="zoom-in">{item.desc}</p>
                                        </div>
                                    </div>

                                </div>

                            ))}
                        </div>
                    </div>

                </div>
            </div>
            {/* why salesforce section ends/ */}


            {/* industry benefits start */}
            <div className="row m-0 bg2">
                <div className="col">

                    <div className="container">
                        <div className="row industry_benefits">
                            <div className="col">
                                <h1 className="common_heading" data-aos="fade-up">{thisPage.service_benefits_heading}</h1>
                                <p className="para">{thisPage.service_benefits_desc}</p>
                            </div>
                        </div>

                        <div className="row">
                            {thisPage.service_benefits.map((item) => (

                                <div className="col-md-6 industry_benefits_card" key={item.id}>
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-12 col-lg-3">
                                            <img src={item.img}  data-aos="fade-up"></img>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-9 ps-3"  data-aos="fade-up" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            <h5 className="title">{item.heading}</h5>
                                            <p className="card-text pt-1">{item.desc}</p>
                                        </div>
                                    </div>

                                </div>

                            ))}
                        </div>
                    </div>

                </div>
            </div>
            {/* industry benefits ends */}


            {/* Comprehensive Services Section starts */}

           
                <div className="container bg3">
                    <div className="row text-white padding">
                        <div className="col">
                            <h1 className="common_heading"  data-aos="zoom-in">{thisPage.comprehensive_service_heading}</h1>
                            <p className="para text-white pb-3">{thisPage.comprehensive_service_desc}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"10px",justifyContent:"center",alignItems:"center"}}>
                        {thisPage.comprehensive_service.map((item) => (

                            
                                <div className="tabs" key={item.id}>
                                {item.title}
                                </div>
                            

                        ))}
                        </div>
                    </div>

                </div>
            

            {/* Comprehensive Services Section ends */}



            {/* solutions section starts*/}


            <div className="container" style={{ paddingBottom: "7rem" }}>
                <div className="row solution-section">
                    <div className="col">
                        <h1 className="common_heading" data-aos="fade-up">{thisPage.offered_solution_heading}</h1>
                        <p className="para">{thisPage.offered_solution_desc}</p>
                    </div>
                </div>

                <div className="row mt-4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    {thisPage.solutions.map((item) => (


                        <div className="col-sm-12 col-md-6 col-lg-3" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <div className="solution_card" key={item.id}>
                                <img src={item.icon} alt="..." data-aos="zoom-in"/>
                                <h5 className="title pt-3 fs-5" data-aos="fade-up">{item.title}</h5>
                                <p className="para_text pt-2 px-4">{item.desc}</p>

                            </div>
                        </div>

                    ))}
                </div>
            </div>

            {/* solution section ends */}

            <ContactForm />
            <Footer />
        </>
    )
}

export default DetailPage