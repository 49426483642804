import React, { useState,useRef } from 'react';
import emailjs from "emailjs-com";
import Forminput from './forminput'
import './forminput.css';
import Swal from 'sweetalert2';
const Forms = () => {

    const [values,setValues]= useState({
      username:'',
      email:'',
      mobile:'',
      message:''
    });

    const inputs = [
      {
        id:1,
        name:'name',
        type:'text',
        placeholder:'Name*',
        errorMessage: 'Please Enter the Username',
        label:'username',
        // pattern:'^[A-Za-z0-9] {3,16}$',
        required:true
      },
      {
        id:2,
        name:'email',
        type:'email',
        placeholder:'Email*',
        errorMessage: 'Please Enter the Email',
        label:'Email',
        pattern:!/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        required:true
      },
      {
        id:3,
        name:'phonenumber',
        type:'phone',
        placeholder:'Mobile No.*',
        errorMessage: 'Please Enter the mobile No.',
        label:'Mobile No,',
        pattern:!/^[0-9]*$/,
        required:true
      },
      // {
      //   id:4,
      //   name:'message',
      //   type:'textarea',
      //   placeholder:'Message',
      //   errorMessage: 'Please Enter the message',
      //   label:'Message',
      //   required:true,
      //   width:["185px"]
      // }
    ]

    const form = useRef();

    const handleSubmit = (e) =>{
      e.preventDefault();
      if (inputs) {
        emailjs
        .sendForm('service_ajcuctg', 'template_24asl6g', form.current, 'QxZUFcPteTc-3ppeF')
        .then(
          () => {
            console.log('SUCCESS!');
            // window.alert('Message Sent Successfully')
            Swal.fire({
              icon: "success",
              iconColor:"#162472",
              title: " Your message send Suceessfully",
              timer: 2000,
              confirmButtonColor:"#39d4df"
            });
           
          },
          (error) => {
            console.log('FAILED...', error.text);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!"
            });
          },
        )
    }
    }

    const onChange =(e)=>{
      setValues({ ...values, [e.target.name]: e.target.value})
    }

    console.log(values)
  return (
    <div className="services_contact">
      
      <form ref={form} onSubmit={handleSubmit}>
      <h5 className="ms-0 py-2">Let's Connect</h5>
        {inputs.map((input)=>(
            <Forminput key={input.id} {...input} value={values[input.name]} onChange={onChange} style={{width: `${inputs.width}`}}/>
        ))}
        <div className="mb-4">
         <textarea type="text" name="message" class="form-control mb-0 mt-2 message" placeholder="Message"  autoComplete='off' onChange={onChange}/>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

<button type="submit" className="btn btn-lg Explore-button mt-0 ms-3" onClick={handleSubmit}>Submit
    {/* <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' /> */}
</button>

</div>
      </form>
    </div>
  )
}

export default Forms
