import React, { useState } from 'react';
import './forminput.css';

const Forminput = (props) => {
  const [focused,setFocused] = useState(false)
  const {label, errorMessage, onChange, id, ...inputProps} = props

  const handleFocus = (e)=>{
    setFocused(true);
  };

   
  return (
    <div>
      {/* <label>{label}</label> */}
    <input {...inputProps} onChange={onChange} onBlur={handleFocus} focused={focused.toString()} class="form-control  mt-2 mb-0"></input>  
    <span className='error'>{errorMessage}</span>    
    </div>
  )
}

export default Forminput
