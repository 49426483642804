import React, { useEffect, useState } from "react";
import './js/jquery.js'
import { NavLink, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ExpJson from './js/industryExp.js'
import Footer from "./footer.jsx";
import { LuChevronRightCircle } from "react-icons/lu";
import Header from "./header.jsx";
import Card from "./service-card.jsx";
import Json1 from '../components/js/salesforce.json';
import ContactForm from "./contact-profile.jsx";
import OurServicesIcon from '../assets/images/ic_our_service.svg';
import arrow from '../assets/images/arrow 1.svg'
import ExpertiseIcon from '../assets/images/ic_our_salesforce_experise.svg';
import IndustryIcon from '../assets/images/ic_Industry Expertise.svg';
import IndustryExp from "./index/industryExp.jsx";
import Testimonial from "./index/testimonial.jsx";
import StaveoIcon from '../assets/images/ic_why.svg';
import Mail from '../assets/images/envelope (2) 2.svg';
import Bulb from '../assets/images/ic_6.svg';
import { GoArrowUpRight } from "react-icons/go";
import SalesforceExp from "./index/salesforceExp.jsx";


function Home() {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })


    const [toggle, setToggle] = useState(1)

    function updateToggle(id) {
        setToggle(id)
    }


    return (
        <>
            <Header />

            {/*  */}

            <section style={{ background: "var(--color-light)" }}>

                <div className="container">
                    <div className="row m-0">
                        <div className="col">
                            <div className="section_heading"><img src={OurServicesIcon} /> Our Services</div>
                            <h1 className="my-3 animate__animated  animate__fadeInUp animate__delay-0.5s">
                                Unlocking Potential Through<br></br> Tailored Solutions
                            </h1>
                            <p className="fs-5 py-3 para_text" data-aos="fade-right">Welcome to the partnership that propels your business forward with tailor-made
                                platform solutions. From inital implementations to intricate custom projects, seamless
                                integrations,ongoing support, and immersive training, we're your trusted ally every step on the way.</p>
                        </div>
                    </div>

                    <div className="row our_services">
                        <Card />
                    </div>
                </div>
            </section>



            {/* --------- Salesforce Expertise section ------------------- */}

            <section>
                <div className="container">

                    <div class="fadeinleft section_heading"> <img src={ExpertiseIcon} alt="..."/> Our Salesforce Expertise </div>
                    
                    <h1 class="mt-3" data-aos="fade-right">Maximize the value of your<br></br>Salesforce</h1>
                    <p class="fs-5 para_text pt-0 my-3 pb-4">Our teams of certified experts are available for all your implementation and improvement
                        projects, across<br></br> all Salesforce Clouds, including:</p>

                   <SalesforceExp/>
                   

                </div>
            </section>


            {/* ------------------------- industry expertise -------------------- */}

            <section className="industry_exp">
                <div className="container">

                    <div className="row m-0">
                        <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                            <div><img src={IndustryIcon} /> Industry Expertise</div>
                            <h1 class="mt-3" data-aos="fade-right">Our skilled team has expertly worked with numerous industries</h1>
                            <p class="py-3 text-white" data-aos="fade-up">We understand your industry needs and challenges and offer Salesforce solutions
                                that are right for you. Every new client<br></br> works with StaveoTech’s dedicated resources who are familiar
                                with your industry.</p>

                        </div>
                    </div>

                   <IndustryExp/>

                </div>
            </section>
            {/*  */}


            {/*  */}        

            <section>
                <div className="container why_staveo">
                    <div class="fs-5 section_heading"><img src={StaveoIcon} /> Why StaveoTech </div>
                    <h1 class="my-4" data-aos="fade-up">Maximize the value of your Salesforce</h1>


                    <div class="row mt-4 mx-5 m-0" style={{ display: "flex", placeContent: "center", boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.05)" }}>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                          
                                <h6 className={toggle === 1 ? "heading_style active-tab" : "heading_style"} onClick={() => updateToggle(1)} data-aos="fade-in">Design</h6>
                        
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                           
                                <h6 className={toggle === 2 ? "heading_style active-tab" : "heading_style"} onClick={() => updateToggle(2)} data-aos="fade-in">Implementation</h6>
                          
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                           
                                <h6 className={toggle === 3 ? "heading_style active-tab" : "heading_style"} onClick={() => updateToggle(3)} data-aos="fade-in">Support</h6>
                         
                        </div>
                    </div>
                    <p className={toggle === 1 ? "show-content pb-3 para_text" : "content"} data-aos="fade-up">Welcome to StaveoTech's Design Services, where your vision meets strategic innovation. We
                        recognize that a well-crafted design sets the foundation for a <br></br>successful Salesforce implementation. Our
                        design services are meticulously crafted to ensure that your Salesforce solution aligns seamlessly with
                        your <br></br>business objectives, enabling a transformative journey ahead.</p>

                    <p className={toggle === 2 ? "show-content pb-3 para_text" : "content"} data-aos="fade-up">
                        Welcome to StaveoTech's Implementation Services, StaveoTech's implementation services are your bridge to a transformed business<br></br> landscape. Let us transform your designs into a living, breathing reality that propels<br></br> your organization to new heights.
                    </p>

                    <p className={toggle === 3 ? "show-content pb-3 para_text" : "content"} data-aos="fade-up">
                        Welcome to StaveoTech's Support Services, Our support services are crafted to ensure that your Salesforce ecosystem remains robust,<br></br> optimized, and aligned with your evolving needs, enabling you to focus on what matters<br></br> most: driving your business forward.
                    </p>

                    {/* <ToggleImages /> */}

                    {/* <Component1/> */}


                    <div className={toggle === 1 ? "show-content row " : "content"}>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card py-2 px-1 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">01</h5>
                                    <span class="card-title fs-5">In Depth Discovery</span>
                                    <div class="card-text mt-3">Our expert consultants dive deep into understanding your business processes,
                                        challenges, and goals. This enables us to create a design blueprint that not only addresses your
                                        current needs but also anticipates future requirements.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">02</h5>
                                    <span class="card-title fs-5">Tailored Solutions</span>
                                    <div class="card-text mt-3">Leveraging our extensive experience, we customize Salesforce to mirror your
                                        unique workflows and business practices. The result is a user-friendly and intuitive system that
                                        accelerates adoption across your organization.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card  px-1 py-2 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">03</h5>
                                    <span class="card-title fs-5">Scalability at Heart</span>
                                    <div class="card-text mt-3">Our designs are future-focused, accommodating your business's growth
                                        trajectory. By considering scalability, we ensure that your Salesforce solution remains relevant and
                                        efficient as your business evolves.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">04</h5>
                                    <span class="card-title fs-5">Seamless Integration</span>
                                    <div class="card-text mt-3">Our holistic approach extends to your existing technology ecosystem. We
                                        design with integration in mind, ensuring that your Salesforce solution seamlessly interacts with
                                        other systems to foster data accuracy and process efficiency.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">05</h5>
                                    <span class="card-title fs-5">Use Centric Design</span>
                                    <div class="card-text mt-3">Your users' needs guide our design principles. We create a solution that
                                        enhances their productivity and empowers them to extract maximum value from Salesforce.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: "-25px" }}>
                            <div class="card px-2 border-0 have-a-project card-contact anim6">


                                <div class="card-body" style={{ textalign: "center" }}>
                                    <img src={Bulb} class="ic_6 mt-2" />
                                    <div class="fs-2 mt-2" style={{ fontfamily: "lato-light", fontweight: "500" }}>Have a Project?</div>
                                    <div class="">We are happy to help you assess, estimate, and provide a roadmap<br></br>  to solving all of your company’s
                                        CRM and data challenges!<br></br>
                                        <img src={Mail} class="me-2" /><u><a style={{ fontFamily: "lato-bold", paddingbottom: "15px" }}>hello@staveotech.com</a></u>
                                    </div>

                                    <div class="contact-overlay">
                                        <NavLink to="/Contact" class=" mx-3">
                                            <button type="submit" className="btn btn-lg Explore-button" id="icon">Contact Us</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- implement section description  --> */}

                    <div className={toggle === 2 ? "show-content row" : "content"} >
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card py-2 px-1 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">01</h5>
                                    <span class="card-title fs-5">Dedicated Project Management</span>
                                    <div class="card-text mt-3">Our experienced project managers orchestrate the entire implementation journey.
                                        From initial planning to post-launch follow-up, we ensure that every phase is meticulously executed and
                                        that you're kept informed at all times.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">02</h5>
                                    <span class="card-title fs-5">Configuration Excellence</span>
                                    <div class="card-text mt-3">Our certified experts configure Salesforce according to the design specifications. By leveraging the platform's capabilities, we maximize its potential to cater to your unique business needs.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card  px-1 py-2 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">03</h5>
                                    <span class="card-title fs-5">Bespoke Customization</span>
                                    <div class="card-text mt-3">Your business is distinct, and so is our approach. We develop custom solutions that extend Salesforce's functionality to mirror your operational requirements precisely, ensuring a CRM that feels tailor-made.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2 fs-6 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">04</h5>
                                    <span class="card-title fs-5">Seamless Data Migration</span>
                                    <div class="card-text mt-3">Transitioning your valuable data is seamless with our data migration specialists. Your historical data is preserved, maintaining continuity and providing historical insights within your new system</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">05</h5>
                                    <span class="card-title fs-5">Empowering Training</span>
                                    <div class="card-text mt-3">Transitioning your valuable data is seamless with our data migration specialists. Your historical data is preserved, maintaining continuity and providing historical insights within your new system.</div>

                                </div>
                            </div>
                        </div>


                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">06</h5>
                                    <span class="card-title fs-5">Thorough Testing</span>
                                    <div class="card-text mt-3">Rigorous testing procedures ensure that your Salesforce solution is free of glitches. We identify and address issues before they become operational hurdles, guaranteeing a smooth launch.</div>

                                </div>
                            </div>
                        </div>


                    </div>


                    {/* <!-- support section description  --> */}

                    <div className={toggle === 3 ? "show-content row" : "content"}>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card py-2 px-1 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">01</h5>
                                    <span class="card-title fs-5">Vigilant Monitoring</span>
                                    <div class="card-text mt-3">Our dedicated support team continuously monitors your Salesforce environment, ensuring its optimal performance. We proactively identify and resolve potential issues before they affect your operations.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">02</h5>
                                    <span class="card-title fs-5">Responsive Troubleshooting</span>
                                    <div class="card-text mt-3">In the event that challenges arise, our experienced support specialists are at your service. Rapid issue identification and resolution minimize disruptions and keep your operations smooth.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card  px-1 py-2 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">03</h5>
                                    <span class="card-title fs-5">Enhancements for Growth</span>
                                    <div class="card-text mt-3">As your business evolves, we're here to ensure that your Salesforce solution evolves with you. Our enhancement services tailor the platform to accommodate your changing requirements, ensuring long-term relevance.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2 fs-6 design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">04</h5>
                                    <span class="card-title fs-5">Saeamless Integration</span>
                                    <div class="card-text mt-3">Salesforce evolves, and we keep you in the loop. Our team informs you about the latest updates and best practices, ensuring that your system stays current and optimized.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="card px-1 py-2  design-sect-card anim6">

                                <div class="card-body">
                                    <h5 class="fs-3">05</h5>
                                    <span class="card-title fs-5">User-Centric Assistance</span>
                                    <div class="card-text mt-3">We extend our support to your users, providing them with the guidance and assistance they need to extract maximum value from Salesforce.</div>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: "-25px" }}>
                            <div class="card px-2 border-0 have-a-project card-contact anim6">


                                <div class="card-body" style={{ textalign: "center" }}>
                                    <img src={Bulb} class="ic_6 mt-2" />
                                    <div class="fs-2 mt-2" style={{ fontfamily: "lato-light", fontweight: "500" }}>Have a Project?</div>
                                    <div class="">We are happy to help you assess, estimate, and provide a roadmap<br></br>  to solving all of your company’s
                                        CRM and data challenges!<br></br>
                                        <img src={Mail} class="me-2" /><u><a style={{ fontFamily: "lato-bold", paddingbottom: "15px" }}>hello@staveotech.com</a></u>
                                    </div>

                                    <div class="contact-overlay">
                                        <NavLink to="/Contact" class=" mx-3">
                                            <button type="submit" className="btn btn-lg Explore-button" id="icon">Contact Us</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*  */}
                    <Link to="/our-services">
                        <button type="submit" className="btn btn-lg Explore-button">Explore More
                            <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                        </button>
                    </Link>




                </div>
            </section>

            {/*  */}


            {/* testimonials section */}

            <Testimonial />


            <ContactForm />

            <Footer />
        </>
    )

}

export default Home;