import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Nav from '../nav.jsx';
import emailjs from 'emailjs-com';
import { LuChevronRightCircle } from "react-icons/lu";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import Swal from 'sweetalert2';
import Footer from '../footer.jsx'

function Career() {

    const form = useRef();

    const defaultJobRoles = [
        {
            category: "Salesforce Technology",
            roles: [
                {name:"Fresher"},
                { name: "Developer" },
                { name: "Project Manager" },
                { name: "QA" },
                { name: "BA" },
            ]
        },
        {
            category: "Sales",
            roles: [
                { name: "Sales Manager" }
            ]
        },
        {
            category: "Marketing",
            roles: [
                { name: "No openings" }
            ]
        }
    ]

    const [isOpen, setIsOpen] = useState(false);

    const [roles, setRoles] = useState('set roles');

    const [selectedCategory, setSelectedCategory] = useState();

    const [role, setRole] = useState([])

    

    const changeDefaultJob = (e) => {
        setRole(defaultJobRoles.find(role => role.category === e.target.value).roles); 
        setSelectedCategory(e.target.value);
         const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    }

    const changeRoles = (e) => {
        setRoles(e.target.value);
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'blue' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
         
          return {
            ...styles,
            backgroundColor: isDisabled ? 'red' : 'blue',
            color: '#FFF',
            cursor: isDisabled ? 'not-allowed' : 'default',
           };
        }
      };

    const [formData, setFormData] = useState({
        fullname: '',
        carreremail: '',
        carrernumber: '',
        experience: '',
        carrermessage: '',
        selectedCategory:'',
        roles:'',
    });

    const [errors, setErrors] = useState({
        fullname: '',
        carreremail: '',
        carrernumber: '',
        experience: '',
        carrermessage: '',
        selectedCategory:'',
        roles:'',
    });

    const [charCount, setCharCount] = useState(0);
      const maxChars = 2000;


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      
      const handleMessageChange = (event) => {
        const inputText = event.target.value;
        if (inputText.length <= maxChars) {
            // setMessage(inputText);
            setCharCount(inputText.length);
          }
          const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const validateForm = () => {
        const newErrors = {};
        if (!formData.fullname.trim()) {
          newErrors.fullname = 'Please Enter your Name';
        }
        // if (!formData.selectedCategory.trim()) {
        //     newErrors.selectedCategory = 'Job Category is required';
        //   }
        //   if (!formData.roles.trim()) {
        //     newErrors.roles = 'Role is required';
        //   }
        if (!formData.carreremail.trim()) {
          newErrors.carreremail = 'Please Enter your Email address';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.carreremail)) {
          newErrors.carreremail = 'Please enter a valid Email address';
        }
        if (!formData.experience.trim()) {
            newErrors.experience = 'Experience is required';
        }
        if (!formData.carrernumber.trim()) {
            newErrors.carrernumber = 'Please Enter your Mobile number';
        }
        else if (!/^(\+?\d{1,3})?\d{9,}$/.test(formData.carrernumber)) {
            newErrors.carrernumber = 'Please Enter a valid Mobile number';
        }
        if (!formData.carrermessage.trim()) {
          newErrors.carrermessage = 'Please Enter your Message';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
 


    const sendmail = (e) => {
        e.preventDefault();
    
           
        if (validateForm()) {
            emailjs
            .sendForm('service_aryce1i', 'template_gmcun7i', form.current, 'QxZUFcPteTc-3ppeF')
            .then(
              () => {
                console.log('SUCCESS!');
                // window.alert('Message Sent Successfully')
                Swal.fire({
                    icon: "success",
                    iconColor:"#162472",
                    title: " Your message send Suceessfully",
                    timer: 2000,
                    confirmButtonColor:"#39d4df"
                  });
              },
              (error) => {
                console.log('FAILED...', error.text);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!"
                  });
              },
            )
        }
      }
    
    return (
        <>

            <Nav />


            <section>
                <div className="container">
                    <div className="row career_heading">
                        <div className="col">
                            <h1>Job opportunities</h1>
                            <p className='para'>
                                We're on the lookout for exceptional individuals to join our dynamic team of Salesforce<br></br> consultants, administrators, developers, and support staff.
                            </p>
                        </div>
                    </div>


                    <div className="row career_form">

                        <div className="col">

                            <h3>Applying is Easy</h3>
                            <p className='text-dark'>
                                Explore our open positions and find perfect fit for your skills and aspirations. Whether you're a seasoned professional or just starting your journey in the world of Salesforce, we welcome diversity and talent.
                            </p>



                            {/* form */}
                            <form className='jobs_from' ref={form} onSubmit={sendmail}>

                            {/* <div className='row career_dropdown mx-1'>

                        <div className='col-6 custom-select'>
                        <button class="btn category gg" type="button" data-bs-toggle="dropdown" aria-expanded="false" onChange={changeDefaultJob}>
                            Functions {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}
                        </button>
                         <ul class="dropdown-menu category_dropdown" onChange={changeDefaultJob}>
                         {defaultJobRoles.map(category => (
                                            <li value={category.category}>{category.category}</li>
                                        ))}
                        </ul>
                        </div>

                        <div className='col-6 custom-select'>
                        <button class="btn category gg" type="button" data-bs-toggle="dropdown" aria-expanded="false" onChange={changeDefaultJob}>
                        Select Job Roles {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}
                        </button>
                        <ul class="dropdown-menu category_dropdown" onChange={changeDefaultJob}>
                        {role.map(roles => (
                                            <li value={roles.name}>{roles.name}</li>
                                        ))}
                        </ul>
                        </div>
                        </div> */}
                        
                            {/* dropdowns */}

                            <div className="row career_dropdown mx-1">
                                <div className="col-sm-12 col-md-12 col-lg-6 bg">
                                    <select name='selectedCategory' onChange={changeDefaultJob}>
                                        
                                        <option value="" disabled selected hidden className='placeholder' style={colourStyles}>Functions</option>
                                        {defaultJobRoles.map(category => (
                                            <option value={category.category}>{category.category}</option>
                                        ))}
                                    </select>
                                    {/* {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)} */}
                                    {errors.selectedCategory && <span className="invalid-style">{errors.selectedCategory}</span>}
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-6 option">
                                    <select name='roles' onChange={changeRoles}>
                                        <option value="" disabled selected hidden>Select Job Roles</option>
                                        {role.map(roles => (
                                            <option value={roles.name}>{roles.name}</option>
                                        ))}
                                    </select>
                                    {/* {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)} */}
                                    {errors.roles && <span className="invalid-style">{errors.roles}</span>}

                                </div>
                            </div>
                                <div className="row my-3">
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <label>Name*</label>
                                        <input type="text" name='fullname' class="form-control mb-0" placeholder="Enter Full Name" onChange={handleChange} autoComplete='off' />
                                        {errors.fullname && <div className="invalid-style">{errors.fullname}</div>}
                                    </div>

                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <label>Experience*</label><br></br>
                                        <input type="text" name="experience" class="form-control mb-0" placeholder='Your Experience (years)' onChange={handleChange} autoComplete='off' />
                                        {errors.experience && <div className="invalid-style">{errors.experience}</div>}
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <label>Email ID*</label>
                                        <input type="email" name='carreremail' class="form-control mb-0" placeholder="Business Email" onChange={handleChange} autoComplete='off' />
                                        {errors.carreremail && <div className="invalid-style">{errors.carreremail}</div>}
                                    </div>

                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <label>Mobile Number*</label>
                                        <input type="name" name='carrernumber' class="form-control mb-0" placeholder="Enter Mobile Number" onChange={handleChange} autoComplete='off' />
                                        {errors.carrernumber && <div className="invalid-style">{errors.carrernumber}</div>}
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col">
                                        <label>Your Message*</label><br></br>
                                        <textarea type="text" name="carrermessage" class="form-control message mb-0" maxLength={maxChars} onChange={handleMessageChange}  autoComplete='off' />
                                        {errors.carrermessage && <div className="invalid-style">{errors.carrermessage}</div>}
                                    </div>

                                </div>

                                <p className='description'>
                                    By submitting this form, I acknowledge receipt of the StaveoTech <Link to="/Privacy-policy" className="underline" style={{ color: "#000" }}>Privacy Policy. </Link>
                                    Fields marked with (*) are required.
                                    <span className='text-dark'>
                                    {charCount} / {maxChars}
                                    </span>
                                </p>


                                <div className='submit_cta'>

                                    <button type="submit" className="btn btn-lg Explore-button mt-3">Submit Form
                                        <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                    </button>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Career;