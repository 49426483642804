import React, { useEffect, useState,useRef } from "react";
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "./nav";
import City from '../assets/images/city 1.svg';
import Marker from '../assets/images/marker (1) 1.svg';
import Network from '../assets/images/social-network 1.svg';
import Mail from '../assets/images/envelope (2) 2.svg';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import Footer from "./footer";
import Swal from 'sweetalert2';

function Contact1() {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

  
    

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
      
      const [errors, setErrors] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
    
      const [charCount, setCharCount] = useState(0);
      const maxChars = 1000;


      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      
      const handleMessageChange = (event) => {
        const inputText = event.target.value;
        // setMessage(inputText);
        setCharCount(inputText.length);
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
          newErrors.name = 'Please Enter your name';
        }
        if (!formData.email.trim()) {
          newErrors.email = 'Please Enter your email address';
        
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Please enter a valid email address';
        
        }
        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Please Enter your mobile number';
        }
        else if (!/^(\+?\d{1,3})?\d{9,}$/.test(formData.phonenumber)) {
            newErrors.phonenumber = 'Please enter a valid mobile number';
        }
        if (!formData.message.trim()) {
          newErrors.message = 'Please write your message';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
 


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
           
        if (validateForm()) {
            emailjs
            .sendForm('service_ajcuctg', 'template_24asl6g', form.current, 'QxZUFcPteTc-3ppeF')
            .then(
              () => {
                console.log('SUCCESS!');
                // window.alert('Message Sent Successfully')
                Swal.fire({
                  icon: "success",
                  iconColor:"#162472",
                  title: " Your message send Suceessfully",
                  timer: 2000,
                  confirmButtonColor:"#39d4df"
                });
               
              },
              (error) => {
                console.log('FAILED...', error.text);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!"
                });
              },
            )
        }
     
      }

    return (
        <>

            <Nav />


            {/* <p className="status">Message sent Successfully.</p> */}
            <div className="container">

                {/* breadcrumb  row */}
                <div className="row">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/* contact info row*/}


            <div className="row m-0">
            
                <div className="col banner1 mobile-banner">
                    <div className="container">


                        <div className="row contact-info">
                            <div className="col-sm-12 col-md-12 col-lg-5 mt-4 info">
                                <h1>Contact Us</h1>
                                <p>For any additional questions, don't hesitate to reach out and discover how StaveoTech can assist in solving your problems.</p>

                                <div className="sub-heading"><img src={City} className="me-2" alt="..."/>Our offices</div>
                                <br></br>
                                <div className="row">
                                  <div className="col-1">
                                  <img src={Marker}  alt="..." /> 
                                  </div>
                                  <div className="col-11 ps-0">
                                    <h5>
                                  Delhi NCR<br></br>
                                  E-168, 3rd Floor, Street No-17, <br></br>
                                  Sadhnagar, Palam Colony,<br></br>
                                  New Delhi - 110045, India
                                  </h5>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-1">
                                  <img src={Marker}  alt="..."/> 
                                  </div>
                                  <div className="col-11 ps-0">
                                  <h5>
                                  Uttar Pradesh<br></br>
                                  Plot no -1, Basement, Om Nagar, Virjapur, <br></br>
                                  NH-2 Mathura, Uttar Pradesh- 281001
                                </h5>
                                  </div>
                                </div>

                                <div className="sub-heading"><img src={Network} className="me-2" alt="..." />Let’s get in touch</div>
                                <Link to="" style={{ textDecoration: "none", color: "#000000" }}>
                                  <div className="row mt-3">
                                    <div className="col-1">
                                    <img src={Mail} alt="..." />
                                    </div>
                                    <div className="col-11 ps-0">
                                    <h5>Email: hello@staveotech.com</h5>
                                    </div>
                                  </div>
                                </Link>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-7 form-display">
                              

                           
                                <div className="form">
                               
                                    <form data-aos="fade-up" ref={form} onSubmit={sendEmail}>
                                    
                                        <label>Full Name*</label><br></br>
                                        {errors.name && <div className="invalid-style">{errors.name}</div>}
                                        <input className='form-control' type="name" name="name" placeholder='Enter Your Name' onChange={handleChange} ></input>


                                        <label>Email ID*</label><br></br>
                                        {errors.email && <div className="invalid-style">{errors.email}</div>}
                                        <input className="form-control" type="email" name="email" placeholder="Business Email" onChange={handleChange}
                                       ></input>


                                        <label>Mobile Number*</label><br></br>
                                        {errors.phonenumber && <div className="invalid-style">{errors.phonenumber}</div>}
                                        <input className="form-control" type="phone" name="phonenumber" placeholder="Enter Mobile Number" onChange={handleChange}
                                        ></input>


                                        <label>Message*</label><br></br>
                                        {errors.message && <div className="invalid-style"> {errors.message}</div>}
                                        <textarea className="form-control message" name="message" placeholder="Type Here..." maxLength={maxChars} onChange={handleMessageChange}
                                      ></textarea>
                                        <p className="count">
                                          {charCount} / {maxChars}
                                        </p>

                                        <p style={{ color: "#000", lineHeight: "1.3" }} className="p-0">By submitting this form, I acknowledge receipt of the StaveoTech <Link to="/Privacy-policy" className="underline" style={{ color: "#000" }}>Privacy Policy. </Link><br></br>
                                            Fields marked with (*) are required.
                                           
                                    </p>

                                        <div className="row">
                                        <button type="submit" className="btn btn-lg Explore-button mt-3" onSubmit={sendEmail}>Submit Form
                                        {/* <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' /> */}
                                    </button>
                                    

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer />


        </>
    )
}

export default Contact1;