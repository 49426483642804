import React, { useRef, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import './style.css'
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import projects from "../js/data";
import { useParams } from "react-router-dom";
import Json from '../../components/js/services.json';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LuChevronRightCircle } from "react-icons/lu";
import Exp from '../js/industryExp.js';
import Logo from '../../assets/images/Logo.svg';
import LinkedIn from '../../assets/images/OUTLINE_copy.svg';
import Call from '../../assets/images/call-icon.svg';
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiMenuAltRight } from 'react-icons/bi'
import ContactForm from "../contact-profile";
import Footer from "../footer";

function Detail() {
    const { productId } = useParams()
    const thisProduct = projects.find(prod => prod.id === productId)
    // console.log(thisProduct)


    const sliderRef = useRef(null)
    // console.log(sliderRef.current)


    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 0.5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplaySpeed: 2000,
                    infinite: true,
                    dots: false,
                    autoplay: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    autoplay: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 2000,
                    autoplay: true
                }
            }
        ]
    };

    const [isOpen, setIsOpen] = useState(false);

    const [isOpen1, setIsOpen1] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })
    return (
        <>
            {/* <!-- header  --> */}
            <header>
                <nav className="navbar navbar-expand-lg fixed-top" id="navlist">
                    <div className="container" data-aos="fade-down">
                        <NavLink className="navbar-brand" to="/"><img src={Logo} id="logo" /></NavLink>
                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <BiMenuAltRight fontSize={50} color="white" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item"    >
                                    <NavLink className="nav hover-line" to="/">Home</NavLink>
                                </li>
                                <div class="dropup-center">
                                    <li className="nav-item dropdown industry">
                                        <NavLink className="nav  hover-line" to="/our-services">
                                            Our Services
                                        </NavLink>


                                        <ul class="dropdown-menu border-0">
                                            {Json.map((item) => (
                                                <li key={item.id} onClick={() => window.location.reload(false)}>
                                                    <Link to={`/our-services/${item.id}`} className="drop">
                                                        {item.title}
                                                    </Link>
                                                </li>

                                            ))}
                                            <li>
                                                <Link to="/our-services/consulting-services" className="drop">Consulting Services</Link>
                                            </li>

                                        </ul>

                                    </li>
                                    <li className="nav-item dropdown exp1">
                                        <NavLink className="nav  hover-line" onClick={() => setIsOpen((prev) => !prev)} type="button" to="/our-services">
                                            Our Services {!isOpen ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}
                                        </NavLink>

                                        {isOpen && (
                                            <ul class="dropdown-menu border-0">
                                                {Json.map((item) => (
                                                    <li key={item.id} onClick={() => window.location.reload(false)}>
                                                        <Link to={`/our-services/${item.id}`} className="drop">
                                                            {item.title}
                                                        </Link>
                                                    </li>

                                                ))}
                                                <li>
                                                <Link to="/our-services/consulting-services" className="drop">Consulting Services</Link>
                                            </li>
                                            </ul>
                                        )}
                                    </li>
                                </div>
                                <li className="nav-item dropdown industry">
                                    <NavLink className="nav hover-line over" to="">Industry Expertise
                                    </NavLink>

                                    <ul class="dropdown-menu border-0">
                                        {Exp.map((industry) => (
                                            <li key={industry.id} onClick={() => window.location.reload(false)}>
                                                <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                    {industry.heading}
                                                </Link>
                                            </li>

                                        ))}
                                    </ul>

                                </li>
                                <li className="nav-item dropdown exp1">
                                    <NavLink className="nav hover-line over" to="" onClick={() => setIsOpen1((prev) => !prev)}>Industry Expertise
                                        {!isOpen1 ? (<MdKeyboardArrowDown className="arrow" />) : (<MdKeyboardArrowUp className="arrow" />)}</NavLink>


                                    {isOpen1 && (
                                        <ul class="dropdown-menu border-0">
                                            {Exp.map((industry) => (
                                                <li key={industry.id} onClick={() => window.location.reload(false)}>
                                                    <Link to={`/Industry-expertise/${industry.id}`} className="drop">
                                                        {industry.heading}
                                                    </Link>
                                                </li>

                                            ))}
                                        </ul>
                                    )}
                                </li>
                                <li className="nav-item" onClick={() => window.location.reload(false)}>
                                    <NavLink className="nav hover-line" to="/career">Careers</NavLink>
                                </li>
                                <li className="nav-item" onClick={() => window.location.reload(false)}>
                                    <NavLink className="nav hover-line" to="/About">About</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/Contact">
                                        <button type="submit" className="btn contact py-2" id="call-btn">Contact Us</button>

                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="">
                                        <img src={mail} className="mailicon" />
                                    </a>
                                </li> */}
                                <li className="nav-item header-icons">
                                    <img src={Call} id="call-icon" alt="..."/>
                                    <a href="https://www.linkedin.com/company/staveo-tech-private-limited/" target="_blank">
                                        <img src={LinkedIn} className="linkedicon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* navbar ends */}

                <div className="container">

                    <div id="carouselExampleAutoplaying" className="carousel slide">


                        <div className="carousel-inner">
                            <div className="carousel-item active">

                                <div className="row m-0 banner-content">

                                    {/* <div className="col-2 empty"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                                        <h1 data-aos="fade-up">{thisProduct.banner.title}</h1>

                                        <p className="pe-5 me-2">{thisProduct.banner.description}</p>
                                        <Link to="/contact">
                                            <button type="submit" className="btn btn-lg Explore-button">Contact Us
                                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                            </button>
                                        </Link>

                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-5 banner-img" data-aos="fade-down">
                                        <img src={thisProduct.bannerimg} className="img-fluid" alt="..."/>
                                    </div>
                                </div>

                            </div>




                        </div>

                    </div>
                </div>

                {/* carousel section ends */}


            </header>
            {/* header ends */}



            {/* transform section starts */}
            <section>
                <div className="container">
                    <div className="row implement_heading">
                        <h1 className="common_heading" data-aos="fade-in">{thisProduct.transformation}</h1>
                        <p className="para" data-aos="fade-in">{thisProduct.transformdes}</p>
                    </div>

                    <div className="row transform">
                        <div className="col-sm-12 col-md-12 col-lg-6 pt-2  ScrollStyle" id="style-3">

                            {thisProduct.transformexperience.map((transform) => (

                                <div key={transform.id}>

                                    <h4 className="sub-heading">{transform.heading}</h4>
                                    <p className="text-dark pt-0 para_style pb-2">{transform.desc}</p>
                                </div>
                            ))}
                        </div>


                        <div className="col-sm-12 col-md-12 col-lg-6 image">
                            <img src={thisProduct.image} className="img-fluid" data-aos="fade-up" alt="..."/>
                        </div>
                    </div>
                </div>
            </section>
            {/* transformation section ends */}


            {/* services section starts */}

            <section id="service_offered">
                <div className="container">
                    <div className="row">
                        <div className="col" data-aos="fade-up">
                            <h1 className="common_heading">{thisProduct.serv_h}</h1>
                            <p className="para mt-3 serv_des">{thisProduct.serv_des}</p>
                        </div>
                    </div>
                </div>

                <div className="row slider" style={{ margin: "0" }}>
                    <div className="col p-0">

                        <Slider ref={sliderRef} {...settings}>
                            {thisProduct.services.map((Services) => (

                                <div className="col-md-3 serv_card" key={Services.id}>
                                    <div className="card border-0">
                                        <h5 className="card-title">{Services.service}</h5>
                                        <p className="card-text para_text text-light pt-2 before">{Services.description}</p>
                                    </div>
                                </div>

                            ))}
                        </Slider>
                        <div className="slick-button">
                            <span className="toggle-button" onClick={() => sliderRef.current.slickPrev()}><FaCircleChevronLeft /></span>
                            <span className="toggle-button" onClick={() => sliderRef.current.slickNext()}><FaCircleChevronRight /></span>
                        </div>
                    </div>


                </div>


            </section>

            {/* services section ends */}


            {/* Benefits section starts */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 p-0 benefits_image">
                            <img src={thisProduct.benefit_image} className="img-fluid" data-aos="fade-right" />
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 " data-aos="fade-up">
                            <div className="row">
                                <h1 className="common_heading">{thisProduct.benefits_h}</h1>
                            </div>

                            <div className="col-11 ScrollStyle1" id="style-4">


                                <div className="row mt-4">
                                    {thisProduct.benefits.map((benefits) => (
                                        <div className=" col-12" key={benefits.id}>
                                            <div className="row">
                                                <div className="col-2 icon_size">
                                                    <img src={benefits.benefit_icon} style={{ width: `(${benefits.width})` }} className="" />
                                                </div>
                                                <div className="col-10 benefits">
                                                    <span className="card-title sub-heading">{benefits.benefit_title}</span>
                                                    <p className="card-text para_style pt-0 ">{benefits.benefit_d}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Benefits section ends */}


            {/* why this service starts*/}

            <section style={{ backgroundColor: "#f9fbff" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-sm-12 col-md-12 col-lg-4 why_this_service">
                            <h1 className="sub_heading">{thisProduct.why_this_service}</h1>
                            <p className="para">{thisProduct.why_this_service_des}</p>
                            <NavLink to="/Contact">
                                <button type="submit" className="btn btn-lg Explore-button mt-2">Contact Us
                                    <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                </button>
                            </NavLink>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">

                            <div className="row card_sect">
                                {thisProduct.whythis.map((why_this_service) => (
                                    <div className="col-sm-12 col-d-12 col-lg-5 service_card" data-aos="zoom-in" key={why_this_service.id}>
                                        <div className="card border-0 pt-4" >
                                            <img src={why_this_service.why_this_icon} />
                                            <h5 className="card-title">{why_this_service.why_this_title}</h5>
                                            <p className="card-text para_style pt-2">{why_this_service.why_this_des}</p>
                                        </div>
                                    </div>

                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* why this service ends */}


            <ContactForm />

            <Footer />
        </>
    )
}

export default Detail