import Home from "./components/home";
import {  Route, Routes } from 'react-router-dom';
import Services from "./components/services/services";
import About from "./components/about/about";
import Career from "./components/policies/career";
import Detail from "./components/services/Detail";
import DetailPage from "./components/industryExpertise/DetailPage";
import Consulting from "./components/services/consulting";
import Policy from "./components/policies/policy";
import Contact1 from "./components/contact1";
import ScrollToTop from "./components/js/scrollTop";


function App({ active, handleChangeActive }) {

  

  return (
    <>

    
    
    
   
    <ScrollToTop/>
    <Routes>
     
      <Route path="/" element={<Home active={active} handleChangeActive={handleChangeActive}/>}></Route>
      <Route exact path="/our-services" element={<Services/>}></Route>
      <Route path="/our-services/:productId" element={<Detail/>}></Route>
      <Route path="/our-services/consulting-services" element={<Consulting/>}></Route>
      <Route path="/Industry-expertise/:pageId" element={<DetailPage/>}></Route>
      <Route path="/About" element={<About/>}></Route>
      <Route path="/Contact" element={<Contact1/>}></Route>
      <Route path="/career" element={<Career/>}></Route>
      <Route path="/Privacy-policy" element={<Policy/>}></Route>
    </Routes>

    
    
    

    </>
  );
}

export default App;
