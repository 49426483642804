import React, { useState,useRef } from "react";
import { LuChevronRightCircle } from "react-icons/lu";
import emailjs from "emailjs-com"
import Swal from 'sweetalert2';


function ContactForm() {

    

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
      
      const [errors, setErrors] = useState({
        name: '',
        email: '',
        phonenumber: '',
        message:''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      
      const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
          newErrors.name = 'Please Enter the Username';
        }
        if (!formData.email.trim()) {
          newErrors.email = 'Please Enter the Email Id';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'Please enter a valid Email Id';
        }
        if (!formData.phonenumber.trim()) {
            newErrors.phonenumber = 'Please Enter the mobile No.';
        }
        else if (!/^(\+?\d{1,3})?\d{9,}$/.test(formData.phonenumber)) {
            newErrors.phonenumber = 'Please Enter a valid mobile No.';
        }
        if (!formData.message.trim()) {
          newErrors.message = 'Please Enter the message';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
 


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
           
        if (validateForm()) {
            emailjs
            .sendForm('service_ajcuctg', 'template_24asl6g', form.current, 'QxZUFcPteTc-3ppeF')
            .then(
              () => {
                console.log('SUCCESS!');
                // window.alert('Message Sent Successfully')
                Swal.fire({
                  icon: "success",
                  iconColor:"#162472",
                  title: " Your message send Suceessfully",
                  timer: 2000,
                  confirmButtonColor:"#39d4df"
                });
               
              },
              (error) => {
                console.log('FAILED...', error.text);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!"
                });
              },
            )
        }
     
             
      }
    
      
    return (
        <>
            {/* contact */}

            <section className="contact-profile">

                <div className="container">
                    <div className="row m-0 flex">
                        <div className="col-sm-12 col-md-6 col-lg-6 content">
                            <h1 data-aos="fade-right">Ready to transform the way your company <br></br>with salesforce ?</h1>
                            <p class="text-white pt-3 fs-5" data-aos="fade-up">Contact us today to take the first step toward powerful automations that
                                increase productivity and maximize ROI.</p>

                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-5 enquiry">

                            <form className="bg-white" ref={form} onSubmit={sendEmail}>
                                <h2>Contact Us</h2>
                                <div class="mb-3">
                                    <input type="name" name="name" id="name" class="form-control mb-0" placeholder="Your name*" onChange={handleChange}/>
                                    {errors.name && <span className="invalid-style">{errors.name}</span>}
                                </div>
                               
                                <div class="mb-3">
                                    <input type="email" name="email" id='email' class="form-control mb-0" placeholder="Email*" onChange={handleChange}/>
                                    {errors.email && <span className="invalid-style">{errors.email}</span>}
                                </div>
                                <div class="mb-3">
                                    <input className="form-control mb-0" name="phonenumber"
                                     placeholder="Mobile No*" onChange={handleChange}/>
                                       {errors.phonenumber && <span className="invalid-style">{errors.phonenumber}</span>}
                                     
                                </div>
                                <div class="">
                                    <textarea type="message" name="message" id="message" class="form-control message mb-0" 
                                    placeholder="Message*" value={formData.message} onChange={handleChange}/>
                                    {errors.message && <span className="invalid-style">{errors.message}</span>}
                                </div>
                                
                              
                              
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button type="submit" className="btn Explore-button my-4">Let's Connect
                                        <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                                    </button>
                                </div>
                            
                            </form>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ContactForm;