
export default [
    {
        "id":"Salesforce-CRM-Implementation",
        "bannerimg":"../../images/fastest_implementation.svg",
        banner:{"title":"Our Salesforce CRM Implementation Service",
        "description": "At StaveoTech, we understand the pivotal role that Customer Relationship Management (CRM) plays in the success of modern businesses. Leveraging the power of Salesforce, we bring you a comprehensive suite of services to transform your operations and elevate your customer engagement strategies."
    },
        "transformation":"Experience Transformation with Our End-to-End Implementation",
        "transformdes":"Unlock the full potential of Salesforce with our end-to-end implementation services. Whether you're looking to enhance your sales processes, amplify your marketing efforts, or provide exceptional service experiences, we have you covered. Our expertise lies in implementing Sales Cloud, Marketing Cloud, and Service Cloud to create a unified CRM ecosystem tailored to your unique business needs.",
        transformexperience:[
            {
                "heading":"Automated Opportunity Management",
                "desc":"Bid farewell to manual and time-consuming opportunity management. Our Salesforce CRM implementation ensures seamless automation, allowing your team to focus on what they do best – building meaningful relationships with clients. By automating repetitive tasks, we enable your sales team to dedicate more time to strategic initiatives that drive revenue"
            },
            {
                "heading":"Streamlined Selling Processes",
                "desc":"Efficiency is at the core of our implementation strategy. We streamline your selling processes, eliminating bottlenecks and ensuring a smooth flow from lead generation to deal closure. With intuitive workflows and customized automation, your team can navigate the sales journey effortlessly, optimizing every interaction with potential customers."
            },
            {
                "heading":"360-Degree Customer View",
                "desc":"A holistic understanding of your customers is a game-changer. Our implementation integrates Sales Cloud, Marketing Cloud, and Service Cloud to provide you with a 360-degree view of your customers. From their initial interaction with your brand to ongoing service interactions, you'll have a comprehensive profile that empowers your team to deliver personalized experiences at every touchpoint"
            },
            {
                "heading":"Crafting a Customer-Centric CRM Ecosystem",
                "desc":"We believe in aligning technology with your customer-centric approach. Our Salesforce CRM implementation is not just about software deployment"
            }   
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"What do our Salesforce Implementation Services offer?",
        "serv_des":"Unlock the full potential of Salesforce with our end-to-end implementation services. Whether you're looking to enhance your sales processes, amplify your marketing efforts, or provide exceptional service experiences.",
        services:[
        {
            "service":"Service Cloud",
            "description":"Streamline your quoting process with Salesforce CPQ. Implementing Salesforce CPQ requires expertise and a thorough knowledge of quote-to-cash processes. Cloudsquare can help you implement CPQ right the first time."
        },
        ,{
            "service":"Sales Cloud",
            "description":"With the implementation of Salesforce Sales Cloud, you can group your accounts faster, find new customers more quickly, and close deals faster – from anywhere."
        },
        {
            "service":"Service Cloud",
            "description":"If you are looking for an improved customer experience, this Salesforce product gives your team a 360-degree view of your customers, enabling you to deliver smarter, faster, and more personalized service."
        },
        {
            "service":"Marketing Cloud",
            "description":"World-class digital marketing platform to unify data sources, personalize on every channel, engage in any stage of their relationship with your brand, and measure the performance of every campaign."
        },
        {
            "service":"Experience Cloud",
            "description":"Whether your company needs to grant portal access to your customers to track orders or support tickets, channel partners to log leads and track activity, or build something completely custom, we cover it all."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Benefits of Salesforce Implementation Services",
        "check_icon":"../../images/ic_check.svg",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Fast Time to Market",
                "benefit_d":"Working with the right Salesforce implementation approach, your business can increase lead conversion, sales revenue, and customer retention."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Unlimited Integrations​",
                "benefit_d":"Gain success faster by automating your business processes and workflows and getting a convenient solution for end-users"
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Improved Productivity",
                "benefit_d":"Effectively streamlined the data-processing efforts and automated outreach, data entry, and file analysis work."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Transparency & Visibility",
                "benefit_d":"Maximized Sales representatives’ productivity and management processes’ efficiency."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Global Market Reach​",
                "benefit_d":"Effectively streamlined the data-Maintain a better track of the customer’s history, past communications, orders’ status, and more by storing all the information in one central place. efforts and automated outreach, data entry, and file analysis work."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team comprises certified Salesforce professionals with extensive experience in CRM implementation across diverse industries"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"We understand that every business is unique. Our implementation process is highly customizable, ensuring a solution that fits your specific requirements"
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"Training and Support",
                "why_this_des":"We don't just implement and leave. We provide comprehensive training to your team and ongoing support to ensure a smooth transition and continued success"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Results-Driven Approach",
                "why_this_des":"Our focus is on delivering tangible results. Whether it's increasing sales efficiency, improving customer satisfaction, or boosting marketing ROI, we're committed to achieving measurable outcomes"
            }
        ]
    },
    {
        "id":"Managed-Support-Services",
        "bannerimg":"../../images/img_2.svg",
        banner:{"title":"Managed Support Services",
        "description": "Invest in the longevity and performance of your Salesforce CRM with StaveoTech Managed Support Services. Experience a seamless, cost-effective, and worry-free operation of your CRM platform. Contact us today to discuss how we can elevate your Salesforce experience to new heights."
    },
        "transformation":"Optimize Your Salesforce Investment",
        "transformdes":"Tailored for businesses seeking ongoing platform enhancement Our managed packages and services provide access to a dedicated team of experts committed to the well-being and upkeep of your Salesforce instance.",
        transformexperience:[
            {
                "heading":"Salesforce Refinement and Evaluation",
                "desc":"Whether you're a recent adopter or a seasoned user of Salesforce, we're here to optimize your workflows, streamline processes, and retire outdated functionalities. Let us guide you in resolving technical debt and seizing untapped opportunities"
            },
            {
                "heading":"Comprehensive Salesforce Assessment",
                "desc":"Our experts meticulously evaluate your Salesforce environment, ensuring you leverage the latest features and functionalities. We provide a roadmap of actionable recommendations to keep you ahead of the curve"
            },
            {
                "heading":"Continuous Improvements",
                "desc":"As your business evolves, so should your Salesforce org. Our dedicated team manages your Salesforce backlog, refines business processes, and facilitates continuous delivery, empowering you to offer transparent leadership insights."
            },
            {
                "heading":"Reports & Dashboards",
                "desc":"Gain deeper insights into your operations. We specialize in crafting bespoke reports and dashboards tailored to your business needs, showcasing pivotal metrics. From individual user performance to departmental progress and company-wide trends, we provide the clarity you seek."
            }   
        ],
        "image":"../../../images/support.webp",
        "serv_h":"What do our Salesforce Managed Services offer?",
        "serv_des":"Elevate your Salesforce experience with StaveoTech Tech's expert managed services designed to propel your business to the next level.",
        services:[
        {
            "service":"Salesforce Optimization",
            "description":"Whether you're a newcomer or a seasoned user of Salesforce, our team specialises in optimising your processes, addressing technical debt, and modernising outdatedfunctionalities to capture missed opportunities effectively."
        },
        ,{
            "service":"Salesforce Health Assessment",
            "description":"Stay ahead of the curve with our comprehensive Salesforce Health Assessment. We evaluate your environment, ensure you're up-to-date with the latest features, and provide a roadmap of recommendations for continual improvement"
        },
        {
            "service":"Ongoing Enhancements",
            "description":"As your business evolves, so should your Salesforce org. Our team manages your backlog, streamlines processes, and facilitates continuous delivery, ensuring transparency to leadership and keeping your organisation ahead of the curve."
        },
        {
            "service":"Analytics & Dashboards",
            "description":"Gain deeper insights into your processes with custom-built reports and dashboards tailored to your business needs. Whether it's tracking departmental progress or gaining a company-wide perspective, we deliver the metrics that matter most to your success"
        },
        {
            "service":"Quarterly Business Review",
            "description":"Partner with us in quarterly business reviews where we set goals, review KPIs, analyse business impact, and strategize for the future. Receive detailed reports and prioritise your roadmap for continued growth and success."
        },
        {
            "service":"Training & User Adoption",
            "description":"Empower your team with comprehensive training on new Salesforce features to drive user adoption and maximise productivity. Our goal is to ensure your team feels confident and empowered to leverage Salesforce for their growth and success."
        }
        ],
        "benefit_image":"../../../images/benefits_img.png",
        "benefits_h":"Benefits of Salesforce Managed Services",
       
        benefits:[
            {
                "benefit_icon":"../../images/sales.svg",
                "benefit_title":"Sales Cloud",
                "benefit_d":"Streamline your sales processes and drive revenue growth."
            },
            {
                "benefit_icon":"../../images/ic_service_cloud.svg",
                "benefit_title":"Service Cloud​",
                "benefit_d":"Elevate customer service experiences and build lasting relationships."
            },
            {
                "benefit_icon":"../../images/ic_Marketing_cloud.svg",
                "benefit_title":"Marketing Cloud",
                "benefit_d":"Launch targeted campaigns and engage customers effectively."
            },
            {
                "benefit_icon":"../../images/financial.svg",
                "benefit_title":"Financial Services Cloud",
                "benefit_d":"Tailored solutions for the financial sector to drive client satisfaction."
            },
            {
                "benefit_icon":"../../images/Integration.svg",
                "benefit_title":"Integration",
                "benefit_d":"Seamlessly connect your Salesforce instance with other platforms for enhanced functionality"
            },
            {
                "benefit_icon":"../../images/ic_CPQ_Cloud.svg",
                "benefit_title":"CPQ & Billing",
                "benefit_d":"Optimise quoting and billing processes for increased efficiency."
            },
            {
                "benefit_icon":"../../images/ic_AppExchange.svg",
                "benefit_title":"AppExchange",
                "benefit_d":"Explore and integrate a variety of third-party apps to extend Salesforce's capabilities."
            },
            {
                "benefit_icon":"../../images/salesforce.svg",
                "benefit_title":"Salesforce Platform",
                "benefit_d":"Leverage the power of the Salesforce platform to build custom solutions."
            },
            {
                "benefit_icon":"../../images/Experience_Cloud2.svg",
                "benefit_title":"Experience Cloud",
                "benefit_d":"Create personalised experiences for customers, partners, and employees."
            },
            {
                "benefit_icon":"../../images/Nonprofit_Success_Pack.svg",
                "benefit_title":"Nonprofit Success Pack",
                "benefit_d":"Tailored solutions for nonprofit organisations to manage relationships and donations."
            },
            {
                "benefit_icon":"../../images/Product_Development.svg",
                "benefit_title":"Product Development",
                "benefit_d":"Collaborate with our team to develop custom solutions aligned with your business objectives."
            },
            {
                "benefit_icon":"../../images/Tableau.svg",
                "benefit_title":"Tableau",
                "benefit_d":"Gain actionable insights and make data-driven decisions with powerful analytics tools."
            },
            {
                "benefit_icon":"../../images/ic_Pardot_Cloud.svg",
                "benefit_title":"Salesforce Pardot",
                "benefit_d":"Drive engagement and nurture leads with sophisticated marketing automation."
            },
            {
                "benefit_icon":"../../images/ic_Mulesoft_Cloud.svg",
                "benefit_title":"MuleSoft",
                "benefit_d":"Unlock data across various systems and applications for unified insights."
            },
            {
                "benefit_icon":"../../images/chat_bot.svg",
                "benefit_title":"Chatbots",
                "benefit_d":"Enhance customer interactions and support processes with intelligent chatbots."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"At StaveoTech, we understand that choosing the right partner for your Salesforce Managed Services is crucial for unlocking the full potential of your Salesforce investment. ",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    },
    {
        "id":"Cloud-Fastest-Implementation",
        "bannerimg":"../../images/fastest_implementation.svg",
        banner:{"title":"Discover Our Salesforce Implementation Services",
        "description": "At Staveo Tech, we specialise in providing top-tier Salesforce implementation services tailored to propel your business growth. Whether seeking expert guidance or comprehensive consulting, you've found the ideal partner."
    },
        "transformation":"Unlock the Power of Salesforce Implementation Services",
        "transformdes":"At Staveo Tech, we recognize the pivotal role of the right Salesforce implementation approach in enhancing lead conversion, boosting sales revenue, and fostering customer retention.",
        transformexperience:[
            {
                "heading":"Streamlined Operations",
                "desc":"Efficiently streamline data processing efforts and automate outreach, data entry, and file analysis tasks, empowering your team to focus on high-value initiatives while eliminating manual redundancies."
            },
            {
                "heading":"Enhanced Productivity",
                "desc":"Maximize sales representatives' productivity and streamline management processes, ensuring optimal efficiency across all facets of your organization."
            },
            {
                "heading":"Improved Collaboration",
                "desc":"Foster faster and more effective collaboration with customers and internal teams, managing contacts and tracking opportunities seamlessly throughout the sales cycle."
            },
            {
                "heading":"Accessible Data",
                "desc":"Generate up-to-date, accurate, and comprehensive information accessible across departments and locations, empowering teams with real-time insights from anywhere with an internet connection."
            } ,
            {
                "heading":"Cost-Effective Maintenance",
                "desc":"Reduce maintenance costs and minimize downtime with automatic upgrades of applications and infrastructure, enabling your business to allocate resources towards more impactful ventures."
            }
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"Our Salesforce Product Expertise Includes",
        "serv_des":"Whether you're a growing business, a department with specialised workflows, or a small business looking to customise Salesforce CRM, we have the expertise to drive your success.",
        services:[
        {
            "service":"Sales Cloud",
            "description":"Accelerate your sales processes, find new customers, and close deals faster with Salesforce Sales Cloud"
        },
        ,{
            "service":"Service Cloud",
            "description":"Gain a 360-degree view of your customers and deliver personalised service experiences with Salesforce Service Cloud."
        },
        {
            "service":"Marketing Cloud",
            "description":"Unify data sources, personalise customer interactions, and measure campaign performance with Salesforce Marketing Cloud."
        },
        {
            "service":"Experience Cloud",
            "description":"Build custom portals for customers, channel partners, and employees with Salesforce Experience Cloud."
        },
        {
            "service":"CPQ & Billing",
            "description":"Streamline your quoting process and enhance revenue management with Salesforce CPQ & Billing."
        },
        {
            "service":"Financial Services Cloud",
            "description":" Increase advisor productivity and deepen customer trust with Salesforce Financial Services Cloud."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Explore Our Salesforce Implementation Services",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Salesforce Consulting and Configuration",
                "benefit_d":"We analyse your business requirements and tailor Salesforce solutions to match your unique workflows and processes. Our expertise ensures that your Salesforce instance is optimised to drive efficiency and productivity."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Customization and Integration​",
                "benefit_d":"From customising Salesforce clouds and products to integrating third-party applications, we ensure that your Salesforce ecosystem aligns seamlessly with your business goals and objectives."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"User Adoption Planning",
                "benefit_d":"We understand the importance of successful user adoption. Our team assists you in planning and implementing strategies to ensure that your team fully embraces Salesforce, maximising its potential impact on your business"
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    },
    {
        "id":"CRM-Migration-to-Salesforce",
        "bannerimg":"../../images/Migration_Banner.svg",
        banner:{"title":"Seamless CRM Migration to Salesforce for Elevated Data Reliability",
        "description": "Embarking on a journey to Salesforce is more than just a migration. it's a strategic move to elevate your data's reliability and quality."
    },
        "transformation":"Advantages of Migration Services with StaveoTech",
        "transformdes":"In the dynamic landscape of business technology, ensuring that your Salesforce CRM remains optimized, up-to-date, and aligned with your evolving business needs is crucial. At StaveoTech, we offer unparalleled Managed Support Services designed to sustain the performance of your Salesforce investments",
        transformexperience:[
            {
                "heading":"Data Integrity Assurance",
                "desc":" Our migration process is designed with a meticulous focus on data integrity. We understand that your data is a valuable asset, and our experts ensure that every piece of information retains its accuracy, consistency, and reliability throughout the migration process."
            },
            {
                "heading":"Secure Migration Protocols",
                "desc":"Security is paramount in the digital age. Our CRM migration services to Salesforce adhere to the highest security standards. We employ encryption, multi-factor authentication, and other advanced security measures to safeguard your data during transit and storage."
            },
            {
                "heading":"Seamless Data Cleansing and Sanitization",
                "desc":"Your data deserves a fresh start. Our migration services include comprehensive data cleansing and sanitization processes. Duplicate records, inconsistencies, and outdated information are meticulously addressed to ensure that your Salesforce instance starts with a clean and optimized database."
            },
            {
                "heading":"Experience-Driven Approach",
                "desc":"Benefit from the experience of our seasoned professionals. Our team has successfully executed numerous CRM migrations to Salesforce, honing our expertise in overcoming challenges and ensuring a smooth transition for businesses of all sizes."
            }   
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"Expert managed services to get you to the next level",
        "serv_des":"Unlock the full potential of Salesforce with our end-to-end implementation services. Whether you're looking to enhance your sales processes, amplify your marketing efforts, or provide exceptional service experiences.",
        services:[
        {
            "service":"Service Cloud",
            "description":"Streamline your quoting process with Salesforce CPQ. Implementing Salesforce CPQ requires expertise and a thorough knowledge of quote-to-cash processes. Cloudsquare can help you implement CPQ right the first time."
        },
        ,{
            "service":"Analytics, Reports & Dashboards",
            "description":"Get deeper insights into your process. We help custom-build reports and dashboards for your business, to show you the metrics that really matter."
        },
        {
            "service":"Salesforce Optimization",
            "description":"Whether you have been using Salesforce for a couple of months or a number of years, we can help you optimize your new processes and efficiently phase out old ones."
        },
        {
            "service":"Health Assessment",
            "description":"World-class digital marketing platform to unify data sources, personalize on every channel, engage in any stage of their relationship with your brand, and measure the performance of every campaign."
        },
        {
            "service":"Experience Cloud",
            "description":"Whether your company needs to grant portal access to your customers to track orders or support tickets, channel partners to log leads and track activity, or build something completely custom, we cover it all."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Key Features of Our CRM Migration Services",
        "check_icon":"../../images/ic_check.svg",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Mapping and Analysis",
                "benefit_d":"We conduct a thorough analysis of your existing CRM data, mapping each element to its corresponding field in Salesforce. This meticulous planning ensures a seamless transition without loss of critical information."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Customized Solutions​",
                "benefit_d":"Recognizing that every business has unique data requirements, we provide customized migration solutions tailored to your specific needs. Whether you're migrating from a different CRM platform or upgrading within Salesforce, our approach is adaptable to your circumstances."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Continuous Monitoring and Support",
                "benefit_d":"Our commitment doesn't end with the migration process. We provide continuous monitoring and support to address any post-migration issues promptly, ensuring that your Salesforce instance continues to operate at its best."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    },
    {
        "id":"Integration-Services",
        "bannerimg":"../../images/Integration_Banner.svg",
        banner:{"title":"Integration Services",
        "description": "In the dynamic landscape of business technology, ensuring that your Salesforce CRM remains optimized, up-to-date, and aligned with your evolving business needs is crucial. At StaveoTech, we offer unparalleled Managed Support Services designed to sustain the performance of your Salesforce investments."
    },
        "transformation":"Unlock the Full Potential of Your Salesforce Investment with Our Managed Support Services",
        "transformdes":"In the dynamic landscape of business technology, ensuring that your Salesforce CRM remains optimized, up-to-date, and aligned with your evolving business needs is crucial. At StaveoTech, we offer unparalleled Managed Support Services designed to sustain the performance of your Salesforce investments",
        transformexperience:[
            {
                "heading":"Meticulous Attention to Updated Releases",
                "desc":"Salesforce is continuously evolving, introducing new features, enhancements, and security updates. Our Managed Support Services ensure that your CRM environment stays current with the latest releases. We meticulously monitor and implement updates, leveraging the full capabilities of Salesforce's innovative tools to enhance your business processes."
            },
            {
                "heading":"Cost-Effective Operations",
                "desc":"Cost efficiency is at the forefront of our Managed Support Services. We understand the importance of maximizing the value of your CRM investment while keeping operational costs in check. Our team of experts works diligently to optimize processes, streamline workflows, and identify opportunities for efficiency gains, ensuring that your Salesforce environment operates at peak performance without unnecessary financial burden."
            },
            {
                "heading":"Robust Maintenance Support Programs",
                "desc":"Preventive maintenance is key to avoiding disruptions in your CRM operations. Our Managed Support Services include robust maintenance support programs that proactively identify and address potential issues. From performance tuning to data integrity checks, we keep your Salesforce environment in top-notch condition, reducing the risk of downtime and ensuring a seamless user experience"
            },
            {
                "heading":"Keeping Your CRM Environment Running at Its Prime",
                "desc":"We believe in aligning technology with your customer-centric approach. Our Salesforce CRM implementation is not just about software deployment"
            }   
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"Expert managed services to get you to the next level",
        "serv_des":"Unlock the full potential of Salesforce with our end-to-end implementation services. Whether you're looking to enhance your sales processes, amplify your marketing efforts, or provide exceptional service experiences.",
        services:[
        {
            "service":"Service Cloud",
            "description":"Streamline your quoting process with Salesforce CPQ. Implementing Salesforce CPQ requires expertise and a thorough knowledge of quote-to-cash processes. Cloudsquare can help you implement CPQ right the first time."
        },
        ,{
            "service":"Analytics, Reports & Dashboards",
            "description":"Get deeper insights into your process. We help custom-build reports and dashboards for your business, to show you the metrics that really matter. Drill down to a set of specific users, track progress of specific departments, or see the total picture company wide."
        },
        {
            "service":"Salesforce Optimization",
            "description":"Whether you have been using Salesforce for a couple of months or a number of years, we can help you optimize your new processes and efficiently phase out old ones. Let us help you resolve technical debt, remove outdated functionalities, and begin capturing those missed opportunities"
        },
        {
            "service":"Health Assessment",
            "description":"World-class digital marketing platform to unify data sources, personalize on every channel, engage in any stage of their relationship with your brand, and measure the performance of every campaign."
        },
        {
            "service":"Experience Cloud",
            "description":"Whether your company needs to grant portal access to your customers to track orders or support tickets, channel partners to log leads and track activity, or build something completely custom, we cover it all."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Benefits of Salesforce Implementation Services",
        "check_icon":"../../images/ic_check.svg",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Fast Time to Market",
                "benefit_d":"Working with the right Salesforce implementation approach, your business can increase lead conversion, sales revenue, and customer retention."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Unlimited Integrations​",
                "benefit_d":"Gain success faster by automating your business processes and workflows and getting a convenient solution for end-users"
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Improved Productivity",
                "benefit_d":"Effectively streamlined the data-processing efforts and automated outreach, data entry, and file analysis work."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Transparency & Visibility",
                "benefit_d":"Maximized Sales representatives’ productivity and management processes’ efficiency."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Global Market Reach​",
                "benefit_d":"Effectively streamlined the data-Maintain a better track of the customer’s history, past communications, orders’ status, and more by storing all the information in one central place. efforts and automated outreach, data entry, and file analysis work."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    },
    {
        "id":"Continous-Enhancements",
        "bannerimg":"../../images/continous_enhancement.svg",
        banner:{"title":"Continous Enhancements Services",
        "description": "In the dynamic landscape of business technology, ensuring that your Salesforce CRM remains optimized, up-to-date, and aligned with your evolving business needs is crucial. At StaveoTech, we offer unparalleled Managed Support Services designed to sustain the performance of your Salesforce investments."
    },
        "transformation":"Unlock the Full Potential of Your Salesforce Investment with Our Managed Support Services",
        "transformdes":"In the dynamic landscape of business technology, ensuring that your Salesforce CRM remains optimized, up-to-date, and aligned with your evolving business needs is crucial. At StaveoTech, we offer unparalleled Managed Support Services designed to sustain the performance of your Salesforce investments",
        transformexperience:[
            {
                "heading":"Meticulous Attention to Updated Releases",
                "desc":"Salesforce is continuously evolving, introducing new features, enhancements, and security updates. Our Managed Support Services ensure that your CRM environment stays current with the latest releases. We meticulously monitor and implement updates, leveraging the full capabilities of Salesforce's innovative tools to enhance your business processes."
            },
            {
                "heading":"Cost-Effective Operations",
                "desc":"Cost efficiency is at the forefront of our Managed Support Services. We understand the importance of maximizing the value of your CRM investment while keeping operational costs in check. Our team of experts works diligently to optimize processes, streamline workflows, and identify opportunities for efficiency gains, ensuring that your Salesforce environment operates at peak performance without unnecessary financial burden."
            },
            {
                "heading":"Robust Maintenance Support Programs",
                "desc":"Preventive maintenance is key to avoiding disruptions in your CRM operations. Our Managed Support Services include robust maintenance support programs that proactively identify and address potential issues. From performance tuning to data integrity checks, we keep your Salesforce environment in top-notch condition, reducing the risk of downtime and ensuring a seamless user experience"
            },
            {
                "heading":"Keeping Your CRM Environment Running at Its Prime",
                "desc":"We believe in aligning technology with your customer-centric approach. Our Salesforce CRM implementation is not just about software deployment"
            }   
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"Expert managed services to get you to the next level",
        "serv_des":"Unlock the full potential of Salesforce with our end-to-end implementation services. Whether you're looking to enhance your sales processes, amplify your marketing efforts, or provide exceptional service experiences.",
        services:[
        {
            "service":"Service Cloud",
            "description":"Streamline your quoting process with Salesforce CPQ. Implementing Salesforce CPQ requires expertise and a thorough knowledge of quote-to-cash processes. Cloudsquare can help you implement CPQ right the first time."
        },
        ,{
            "service":"Analytics, Reports & Dashboards",
            "description":"Get deeper insights into your process. We help custom-build reports and dashboards for your business, to show you the metrics that really matter. Drill down to a set of specific users, track progress of specific departments, or see the total picture company wide."
        },
        {
            "service":"Salesforce Optimization",
            "description":"Whether you have been using Salesforce for a couple of months or a number of years, we can help you optimize your new processes and efficiently phase out old ones. Let us help you resolve technical debt, remove outdated functionalities, and begin capturing those missed opportunities"
        },
        {
            "service":"Health Assessment",
            "description":"World-class digital marketing platform to unify data sources, personalize on every channel, engage in any stage of their relationship with your brand, and measure the performance of every campaign."
        },
        {
            "service":"Experience Cloud",
            "description":"Whether your company needs to grant portal access to your customers to track orders or support tickets, channel partners to log leads and track activity, or build something completely custom, we cover it all."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Benefits of Salesforce Implementation Services",
        "check_icon":"../../images/ic_check.svg",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Fast Time to Market",
                "benefit_d":"Working with the right Salesforce implementation approach, your business can increase lead conversion, sales revenue, and customer retention."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Unlimited Integrations​",
                "benefit_d":"Gain success faster by automating your business processes and workflows and getting a convenient solution for end-users"
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Improved Productivity",
                "benefit_d":"Effectively streamlined the data-processing efforts and automated outreach, data entry, and file analysis work."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Transparency & Visibility",
                "benefit_d":"Maximized Sales representatives’ productivity and management processes’ efficiency."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Global Market Reach​",
                "benefit_d":"Effectively streamlined the data-Maintain a better track of the customer’s history, past communications, orders’ status, and more by storing all the information in one central place. efforts and automated outreach, data entry, and file analysis work."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    },
    {
        "id":"Salesforce-Development-Services",
        "bannerimg":"../../images/development_services.svg",
        banner:{"title":"Salesforce Development Services",
        "description": "Transform your business with StaveoTech's Salesforce Development Services. Unleash the full potential of your Salesforce CRM investment with our expert team."
    },
        "transformation":"Advantages of Salesforce Custom Development Services",
        "transformdes":"Unlock the full potential of Salesforce with Staveo Tech's custom development services. Let us empower your organization to thrive in the digital era and drive sustainable growth",
        transformexperience:[
            {
                "heading":"Enhanced Customer Experience",
                "desc":"Our custom Salesforce development solutions empower your team to efficiently manage custom demands, provide feedback, handle transactions, and more, elevating the overall customer experience."
            },
            {
                "heading":"Mobile Apps Accessibility",
                "desc":"Access Salesforce from any mobile device to enhance functionality and agility within your organization. Empower your employees to process activities efficiently from anywhere with internet access, ensuring swift deal management, seamless client interactions, and continuous business oversight."
            },
            {
                "heading":" Seamless Integrations",
                "desc":"Effortlessly integrate new applications and data sources with Salesforce's easy API integration capabilities. Our custom Salesforce application development enhances interoperability by seamlessly integrating various applications and services into your existing platform."
            },
            {
                "heading":"Increased Lead Conversions",
                "desc":"Analyze customer queries, streamline calendars and reports, expedite response times, and cultivate stronger customer relationships to boost lead conversions and sales growth."
            },
            {
                "heading":"Cost, Time, and Effort Savings",
                "desc":"Automate routine tasks, generate reports efficiently, and access real-time data updates from centralized storage. Minimize client response times, streamline service delivery, and enhance overall business operations in one unified platform, saving valuable resources and boosting operational efficiency."
            }      
        ],
        "image":"../../../images/implement.webp",
        "serv_h":"Salesforce Custom Development Offerings",
        "serv_des":"With our specialized Salesforce custom app development services, Salesforce CRM becomes a catalyst for accelerating workforce productivity and enhancing customer collaboration and satisfaction",
        services:[
        {
            "service":"Lightning Components",
            "description":"Craft pixel-perfect, lightning-ready front-end applications tailored to your needs. Automate business processes and create delightful customer experiences with our experienced team of Salesforce developers."
        },
        ,{
            "service":"Apex Development",
            "description":"Ensure secure and tested business logic on the backend. Leverage the power of Apex programming language to add custom functionalities, execute flows, and integrate with third-party applications."
        },
        {
            "service":"API Integrations",
            "description":"Seamlessly connect Salesforce with legacy systems or third-party databases. Leverage extensive scale integrations to migrate data and unlock the full potential of your Salesforce platform."
        },
        {
            "service":"Custom Salesforce Application Development",
            "description":"Tailor Salesforce to your unique requirements. Streamline workflows, automate processes, and expand functionality with our customer-focused solutions and on-demand development services."
        },
        {
            "service":"Mobile App Development",
            "description":"Extend Salesforce capabilities to any mobile device. Enhance collaboration, increase user engagement, and ensure a secure mobile experience with our Salesforce Mobile platform solutions."
        },
        {
            "service":"AppExchange App Development",
            "description":"Unlock the power of the Salesforce AppExchange marketplace. As certified partners, we specialize in building cloud-based applications tailored to your business needs, spanning various verticals."
        },
        {
            "service":"Release Management with Devops",
            "description":"Streamline release management and deployments with leading DevOps tools. Benefit from DevOps best practices and frameworks to enhance deployment success across your organization."
        }
        ],
        "benefit_image":"../../../images/benefits.webp",
        "benefits_h":"Benefits of Salesforce Development Services",
        "benefits_p":"With our specialized Salesforce custom app development services, Salesforce CRM becomes a catalyst for accelerating workforce productivity and enhancing customer collaboration and satisfaction.",
        benefits:[
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Salesforce Consulting",
                "benefit_d":"Every project begins with a comprehensive discovery phase. We listen attentively to your objectives, pain points, and desired outcomes. Our team collaborates closely with yours to understand your processes and outline detailed business requirements, setting the foundation for a successful project plan."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Customization and Configuration​",
                "benefit_d":"Our methodology enables us to build revolutionary tools that amplify your organization's capabilities and enhance user experience. We design workflows to tailor your Salesforce solution, ensuring scalability and ease of use while aligning with your business objectives."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":" Integration",
                "benefit_d":"We seamlessly integrate your Salesforce solution with third-party systems, enhancing productivity and ensuring increased visibility of crucial data across applications."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Data Migration",
                "benefit_d":"Our expert consultants devise robust strategies for successful data migration, adhering to Salesforce best practices to ensure data integrity and accessibility while mitigating the risk of duplicated entries."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Global Market Reach​",
                "benefit_d":"Effectively streamlined the data-Maintain a better track of the customer’s history, past communications, orders’ status, and more by storing all the information in one central place. efforts and automated outreach, data entry, and file analysis work."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"System Setup & Reporting",
                "benefit_d":"We optimize user experience and workflow efficiency by configuring permissions, creating reports, dashboards, and list views to provide your team with actionable insights."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"User Training & Go-live Support",
                "benefit_d":"Our commitment extends beyond project completion. We conduct comprehensive company-wide training sessions to ensure a smooth transition. We offer post-go-live support, addressing any requests promptly to facilitate continuous improvement."
            },
            {
                "benefit_icon":"../../images/ic_check.svg",
                "benefit_title":"Evolution and Continuous Support​",
                "benefit_d":"Our Managed Services offer ongoing support and enhancements to your Salesforce platform, ensuring continued success and growth across various Salesforce products."
            }
        ],
        "why_this_service":"Why Choose StaveoTech?",
        "why_this_service_des":"Embark on a journey of transformation with StaveoTech. Elevate your business with Salesforce CRM implementation that goes beyond technology – it's about revolutionizing the way you connect with your customers. Contact us today to get started on your path to CRM excellence!",
        whythis:[
            {
                "why_this_icon":"../../images/ic_1.svg",
                "why_this_title":"Expertise",
                "why_this_des":"Our team of certified Salesforce professionals brings a wealth of expertise in managing and optimizing Salesforce environments"
            },
            {
                "why_this_icon":"../../images/ic_2.svg",
                "why_this_title":"Customization",
                "why_this_des":"Our Managed Support Services are tailored to your specific business requirements, providing a personalized approach that aligns with your unique goals."
            },
            {
                "why_this_icon":"../../images/ic_5.svg",
                "why_this_title":"24/7 Support",
                "why_this_des":"Business never sleeps, and neither do we. Our support team is available around the clock to address any issues promptly and keep your Salesforce environment running smoothly"
            },
            {
                "why_this_icon":"../../images/ic_4.svg",
                "why_this_title":"Proactive Approach",
                "why_this_des":"We don't wait for issues to arise; we proactively monitor and address potential challenges, ensuring the continuous and reliable operation of your CRM"
            }
        ]
    }

]
