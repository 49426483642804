import React from "react";
import { Link } from 'react-router-dom'
import './css/style.css';
import Logo from '../assets/images/Logo.svg';
import Exp from './js/industryExp'
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import Json from '../components/js/services.json';
function Footer() {

    return (
        <>

            <div className="footer">
                <div className="row m-0">
                    <div className="col">

                        {/* footer starts */}
                        <div className="container">
                            <div className="row footer-link">
                                <div className="col-sm-6 col-md-3 col-lg-4  footer-logo">
                                    <img src={Logo} alt="..." />
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-4 h1 text-center" style={{display:"flex",alignItems:"flex-end",justifyContent:"center"}}>
                                    <h1 className="size">hello@staveotech.com</h1>
                                </div>
                                <div className="col-sm-12 col-md-3 col-lg-4 social-connect">

                                    <div>
                                        <FaYoutube fontSize={31} className="mx-2" />
                                        <FaInstagram fontSize={28} className="mx-2" />
                                        <a href="https://www.linkedin.com/company/staveo-tech-private-limited/" target="_blank ">
                                            <FaLinkedin fontSize={26} className="mx-2" color="#fff" />
                                        </a>
                                    </div>

                                    <h6 className="fs-5 mt-1">Social Connect</h6>
                                </div>
                            </div>


                            <div className="row footer-content">
                                <div className="col-sm-12 col-md-12 col-lg-3">
                                    <h5>Our Services</h5>
                                    <ul>
                                        {Json.map((item) => (
                                            <li key={item.id}>
                                                <Link to={`/our-services/${item.id}`} className="links">
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                        <li>
                                                <Link to="/our-services/consulting-services" className="drop links">Consulting Services</Link>
                                            </li>

                                    </ul>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-3">
                                    <h5>Industry Expertise</h5>
                                    <ul>
                                        {Exp.map((item) => (
                                            <li key={item.id}>
                                                <Link to={`/Industry-expertise/${item.id}`} className="links">
                                                    {item.heading}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-2">
                                    <h5>Company</h5>
                                    <ul className="links">
                                        <Link to="/About" style={{ textDecoration: "none", color: "white" }}><li>About Us</li></Link>
                                        {/* <Link to="#" style={{textDecoration:"none",color:"white"}}><li className="company">Blogs</li></Link> */}
                                        <Link to="/career" style={{ textDecoration: "none", color: "white" }}><li className="company">Careers</li></Link>
                                        <Link to="#" style={{ textDecoration: "none", color: "white" }}><li> Terms & Conditions </li></Link>
                                        <Link to="/Privacy-policy" style={{ textDecoration: "none", color: "white" }}><li>Privacy Policy</li></Link>
                                    </ul>
                                </div>
                            </div>


                            <div className="row policy-content">
                                <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                                    ©2023 StaveoTech Pvt. Ltd., All rights reserved
                                </div>

                            </div>

                        </div>


                        {/* footer ends */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default Footer;