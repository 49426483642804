

import $ from 'jquery';

// header changing color
$(window).on("scroll",function(){
    if($(window).scrollTop()>50){
        $(".navbar").addClass("active");
    }
    else
    {
        $(".navbar").removeClass("active");
    }
});

let image1=document.getElementById(1);
let image2=document.getElementById(2);
