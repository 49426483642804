import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import { LuChevronRightCircle } from "react-icons/lu";
import r_arrow from '../../assets/images/r_arrow.svg'
import l_arrow from '../../assets/images/l_arrow.svg'
import Client1 from '../../assets/images/logo.webp'
import Client2 from '../../assets/images/client2.webp'

function Testimonial() {
 
    const sliderRef = useRef(null)
    // console.log(sliderRef.current)


    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2500,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initalSlide: 1,
        responsive: [
            {
                breakpoint: 1094,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplaySpeed: 2500,
                    infinite: true,
                    dots: false,
                    autoplay: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    autoplay: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 2500,
                    prevArrow: false,
                    nextArrow: false,
                    autoplay: true
                }
            }
        ]
    }


    return (
        <>
            <section className="testimonial">

                <div className="row m-0 testimonial-display">
                    <div className="col-1"></div>
                    <div className="col-sm-12 col-md-12 col-lg-3">
                        <h1>What our clients say about us</h1>
                        <p className="para_text fs-5 pt-1">A Glimpse into Our Exceptional Service and Satisfaction</p>
                        <Link to="/Contact">
                            <button type="submit" className="btn btn-lg Explore-button mt-0">Let's Connect
                                <LuChevronRightCircle fontSize={19} className="ms-2" color='#162472' />
                            </button>
                        </Link>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-7">


                        <Slider ref={sliderRef} {...settings}>

                            <div className="col client1 client-review">
                                <div className="card">
                                    <div className="card border-0">
                                        <img src={Client1} alt="..." />
                                        <ImQuotesLeft className="quotes" color="#789dc06e" />
                                        <p className="card-text para_text">
                                            Seamless Excellence! StaveoTech is a team that truly knows their craft. Collaborating with them was a pleasure from start to finish. What they committed to delivering at the project's onset was not only met but exceeded. The project manager showcased a remarkable ability to foresee and resolve issues, providing solutions to problems we hadn't even identified.  took the time to delve into our processes, asking the right questions and proposing solutions that were both effective and efficient. Excitedly looking forward to our next joint project."
                                        </p>
                                        <span>
                                            <ImQuotesRight color="#789dc06e" className="quotes" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col client2 client-review">
                                <div className="card">
                                    <div className="card border-0">

                                        <img src={Client2} alt="..." />
                                        <ImQuotesLeft className="quotes" color="#cda07f59" />
                                        <p className="card-text para_text">
                                            Strategic Prowess in Implementation! In the dynamic landscape of a startup, we faced distinctive challenges.  not only assisted us in the initial rollout of Salesforce but also played a pivotal role in mapping out our future needs. Their team crafted a tailored, strategic solution that positions us for scalable growth. 's expertise and meticulous attention to detail have made a lasting impression on our team.
                                        </p>
                                        <span>
                                            <ImQuotesRight color="#cda07f59" className="quotes" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col client3 client-review">
                                <div className="card">
                                    <div className="card border-0">

                                        <img src={Client1} alt="..." />
                                        <ImQuotesLeft className="quotes" color="#cebd6a56" />
                                        <p className="card-text para_text">
                                            Seamless Excellence!  is a team that truly knows their craft. Collaborating with them was a pleasure from start to finish. What they committed to delivering at the project's onset was not only met but exceeded. The project manager showcased a remarkable ability to foresee and resolve issues, providing solutions to problems we hadn't even identified.  took the time to delve into our processes, asking the right questions and proposing solutions that were both effective and efficient. Excitedly looking forward to our next joint project."
                                        </p>
                                        <span>
                                            <ImQuotesRight color="#cebd6a56" className="quotes" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col client2 client-review">
                                <div className="card">
                                    <div className="card border-0">

                                        <img src={Client2} alt="..." />
                                        <ImQuotesLeft className="quotes" color="#cda07f59" />
                                        <p className="card-text para_text">
                                            Strategic Prowess in Implementation! In the dynamic landscape of a startup, we faced distinctive challenges. StaveoTech not only assisted us in the initial rollout of Salesforce but also played a pivotal role in mapping out our future needs. Their team crafted a tailored, strategic solution that positions us for scalable growth. StaveoTech's expertise and meticulous attention to detail have made a lasting impression on our team.
                                        </p>
                                        <span>
                                            <ImQuotesRight color="#cda07f59" className="quotes" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </Slider>
                        <div className="slick-button2">
                            <div className="toggle-button2" onClick={() => sliderRef.current.slickPrev()}><img src={r_arrow} /></div>
                            <div className="toggle-button2" onClick={() => sliderRef.current.slickNext()}><img src={l_arrow} /></div>
                        </div>

                    </div>
                </div>

            </section>
        </>
    )

}

export default Testimonial;